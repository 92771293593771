//
// Forms
// --------------------------------------------------

input[type='tel'],
input[type='text'],
input[type='email'],
input[type='number'],
input[type='search'],
textarea,
select {
  appearance: none;
  // @TODO: Replace colors with variables?
  background-color: palette(white);
  border: 1px solid #DDDDDD;
  border-radius: 0;
  box-sizing: border-box;
  color: $color--text;
  display: block;
  padding: 0 rem-calc(30);
  font-family: $font-family--body;
  font-size: rem-calc(20);
  font-weight: 400;
  line-height: 1.25em;
  height: rem-calc(60);
  vertical-align: middle;
  resize: none;
  outline: 0;
  width: 100%;
  
  &::placeholder {
    color: #868686;
  }
}

select {
  background: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==") no-repeat calc(100% - 10px) center #f1f2f3;
  background-size: rem-calc(10);

  option {
    font-size: 16px;
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  color: $color--text;
  height: rem-calc(220);
  padding-top: rem-calc(15);

  .gform_wrapper & {
    color: $color--text;
  }
}

input[type='search'] {
  appearance: none;
  background-color: transparent;
  border: 1px solid palette(grey, light);
  border-radius: 0;
  box-sizing: border-box;
  padding: rem-calc(10 15);
  font-family: $font-family--heading;
  font-weight: 400;
  vertical-align: middle;
  resize: none;
  width: 100%;

  &::placeholder {
    color: palette(grey, light);
  }
}

input[type='submit'] {
  @include button;
}

.search-submit {
  background: transparent;
  border: 0;
  display: block;
  padding: 0;
  height: 100%;
  width:  100%;

  .icon-search-find {
    color: palette(grey, light);
  }
}



// Gravity Forms

body img.gform_ajax_spinner {
  display: none !important;
}

.gform_validation_container {
  display: none;
}

.gform_fields{
  display:         flex;
  flex-flow:       row wrap;
  justify-content: space-between;
  align-items:     flex-start;

}

.gfield{
  flex: 1 1 100%;
}

.gfield.half-col,
.name_first,
.name_last {
  // @TODO: Update to use flexbox?
  @include pie-clearfix;
  margin-bottom: rem-calc(32);
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @include from(lp) {    
    width: 48.625%;
    width: calc(50% - #{rem-calc(16)});
    flex: 0 1 calc(50% - #{rem-calc(16)});
  }
}

.name_first {
  @include from(lp) {
    margin-bottom: 0;
  }
}

.name_last {
  margin-bottom: 0;
}

.ginput_complex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  span {
    display: block;
  }

  @include from(pt) {
    .ginput_full {
      clear: both;
    }
    .ginput_left {
      float: left;
      width: 48%;
    }
    .ginput_right {
      float: right;
      width: 48%;
    }
  }
}

.gclear {
  clear: both;
}

.gfield_radio,
.gfield_checkbox {
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  input {
    margin-right: rem-calc(10);
  }
}

li.gfield {
  border: 0 !important;
  margin: rem-calc(0 0 30);
  padding: 0;
  list-style-type: none;
  list-style-image: none;

  li {
    list-style-type: none;
    list-style-image: none;
  }
}

.gform_wrapper {
  .gform_title {
    @extend .gamma;        
  }


  .gsection {
    border-bottom: 0;
    margin-top: rem-calc(20);
  }

  .ginput_complex label,
  .gfield_label {
    display: none;
    font-family: $font-family--heading;
    font-weight: 700;
    letter-spacing: $letter-spacing--medium;
    text-transform: uppercase;
  }

  .show-label {
    .gfield_label {
      display: block;
    }
  }

  .validation_message,
  .validation_error {
    font-size: 12px;
    font-weight: 700;
  }

  .validation_error {
    background-color: palette(red);
    border-radius: 0;
    padding: rem-calc(10);
    color: palette(white);
    margin-bottom: rem-calc(30);
    font-size: 18px;
    font-weight: $font-weight--bold;    
    padding: rem-calc(20 30);
    @include from(pt) {
      font-size: rem-calc(18);
    }
  }

  form {
    .instruction {
      display: none;
    }

    ul {
      margin:  0;
      padding: 0;
    }

    input[type='submit'] {
      @include button;
    }

    .ginput_card_security_code {
      margin-top: rem-calc(10) !important;
    }

    .clear-multi {
      > div {
        display: inline-block;
        margin-right: rem-calc(10);

        &:last-child {
          margin-right: 0;
        }
      }

      input, select {
        margin-top: 0;
      }

      .ginput_container {
        vertical-align: middle;
      }
    }

    .gform_footer {
      //margin-top: rem-calc($spacer-unit * 3);
      text-align: right;
    }
  }
}

.gfield_error {
  position: relative;

  .validation_message {
    position: absolute;
    top: 50%;
    right: 30px;
    border-radius: 50%;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-indent: -99999px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22icon-error_outline%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Eerror_outline%3C%2Ftitle%3E%0A%3Cpath%20fill%3D%22%23003b4%22%20class%3D%22path1%22%20d%3D%22M12%2020.016c4.406%200%208.016-3.609%208.016-8.016s-3.609-8.016-8.016-8.016-8.016%203.609-8.016%208.016%203.609%208.016%208.016%208.016zM12%202.016c5.531%200%209.984%204.453%209.984%209.984s-4.453%209.984-9.984%209.984-9.984-4.453-9.984-9.984%204.453-9.984%209.984-9.984zM11.016%206.984h1.969v6h-1.969v-6zM11.016%2015h1.969v2.016h-1.969v-2.016z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");

    .loader-request & {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22icon-error_outline%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Eerror_outline%3C%2Ftitle%3E%0A%3Cpath%20fill%3D%22%23fff%22%20class%3D%22path1%22%20d%3D%22M12%2020.016c4.406%200%208.016-3.609%208.016-8.016s-3.609-8.016-8.016-8.016-8.016%203.609-8.016%208.016%203.609%208.016%208.016%208.016zM12%202.016c5.531%200%209.984%204.453%209.984%209.984s-4.453%209.984-9.984%209.984-9.984-4.453-9.984-9.984%204.453-9.984%209.984-9.984zM11.016%206.984h1.969v6h-1.969v-6zM11.016%2015h1.969v2.016h-1.969v-2.016z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
    }
  }
}

.gform_confirmation_wrapper {
  line-height: 1.5;
  text-align: center;
}

// Datepicker
.ui-datepicker {
  background-color: palette(white);
  border: none;
  display: none;
  padding: rem-calc(0 0 0);
  z-index: 99999 !important;
  box-shadow: 0 0 10px rgba(#000, 0.15);

  select {
    background-color: palette(grey, lighter);
    border: 0;
    border-radius: rem-calc(8);
    margin-top:    rem-calc(5);
    margin-bottom: rem-calc(5);
    width: 48%;

    &:first-child {
      margin-right: 4%;
    }
  }

  table {
    //border--top: 1px solid palette(grey, lighter);
    border: none;
    margin: 0;
  } 

  td,
  th {
    border: 1px solid palette(grey, lighter);
    text-align: center;
    width: rem-calc(40);
    padding: 0;

    span,
    a {
      display: block;
      width: 100%;      
      font-size: rem-calc(22);
      text-align: center;
      color: $color--text;
    }
  }

  td {
    &:hover,    
    &.ui-datepicker-current-day {
      
      background-color: palette(brand, accent);
      a {
        color: palette(white);
        
      }
    }
    
    &.ui-datepicker-unselectable{
      span{
        opacity: 0.3;
      }      
    }
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: $font-weight--regular;
    text-transform: uppercase;
    position: absolute;
    top:0;
    height: 100%;

    &:after{
      @include icon;
      color: palette(brand);
      position: absolute;
      top:  50%;    
      display: block;  
      line-height: 1em;
      font-size: rem-calc(36);
      margin-top: rem-calc(-16);
      padding: 0;      
    }
  }

  .ui-datepicker-prev {    
    left: rem-calc(10);

    &:after {      
      @extend %left;
      left: 0;
    }
  }

  .ui-datepicker-next {    
    right: rem-calc(10);

    &:after {      
      @extend %right;
      right: 0;
    }
  }

  .ui-datepicker-header {
    margin-bottom: rem-calc(0);
    padding: rem-calc(10 0);
    
    &,
    .ui-datepicker-month,
    .ui-datepicker-year{
      font-size: rem-calc(20);
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: $letter-spacing--small;  
    }
  }

  .ui-datepicker-today {
    background-color: palette(grey, lighter);
  }
}
