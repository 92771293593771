//
// Header
// --------------------------------------------------
$_book-button-padding: rem-calc(15 30);

.hamburger {
  outline: 0;
}

.float-wrapper,
.region--header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.book-now-btn {
  display: none;

  @include from(lt) {
    white-space: nowrap;
    display: block;
    padding: $_book-button-padding;
  }
}

.nav__toggle {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 30px;
  z-index: 1250;
  position: absolute;
  top: 0;
  right: 0;
  height: 6rem;
  padding-right: 2.2rem;
  height: $header-height-mobile;

  &__trigger {
    padding: 0;
  }

  &:focus,
  &__trigger:focus {
    outline: 0;
  }

  .header-float & {
    transform: translateY(-100%);
    transition: transform 0.4s $ease-out-cubic;
    position: fixed;

    &.header-up {
      transform: translateY(-100%);
    }

    &.header-down {
      transform: translateY(0%);
    }
  }
}

.hamburger-box {
  display: block;

  @include from(lt) {
    display: none;
  }
}

.hero-nav-color--light:not(.header-down) {
  .hamburger-inner {
    &,
    &:before,
    &:after {
      background: palette(white);
    }
  }

  .flyout__content {
    .btn {
      border-color: $button-color-default;
      color: $button-color-default;

      &:hover {
        border-color: $button-color-default;
        background: $button-color-default;
        color: palette(white);
      }
    }
  }

  header {
    .btn {
      &.btn--icon-btn {
        border-color: transparent;
      }
  
      border-color: palette(white);
      color: palette(white);
  
      &:hover {
        background: palette(white);
        color: $color--text;
      }
    }
  }
}

.header-down:not(.is-active):not(.menu-active),
.header-up:not(.is-active):not(.menu-active) {
  .hamburger-inner {
    &,
    &:before,
    &:after {
      background-color: $color--text;
    }
  }

  .btn {
    border-color: $color--text;
    color: $color--text;

    &:hover {
      border-color: $color--text;
      background: $color--text;
      color: palette(white);
    }
  }
}

.region--header {
  @include add-horizontal-spacing(80);

  position: relative;
  z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  height: $header-height-mobile;
  background-color: transparent;
  transition: background-color 0.3s $ease-out-cubic, height 0.2s $ease-out-cubic;

  @include from(d) {
    height: $header-height-default;
  }


  .header-float & {
    background-color: $header-background;
    box-shadow: 0px 0px 10px rgba(#000, 0.15);
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0s linear;

    .logo {
      width: $logo-width-mobile;

      @include from(lt) {
        width: $logo-width-default;
      }
    }

    &.header-up,
    &.header-down {
      transition: transform 0.4s $ease-out-cubic;
      background: palette(white);
      height: $header-height-default / $header-height-float;

      @include from(lt) {
        height: $header-height-default;
      }
    }

    &.header-up {
      transform: translateY(-100%);
    }

    &.header-down {
      transform: translateY(0%);
    }
  }

  .hamburger-box {
    vertical-align: middle;

    .hamburger-inner {
      &,
      &:before,
      &:after {
        transition-property: background;
        transition-duration: .25s;
        transition-timing-function: linear;
      }
    }
  }

  .logo {
    display: block;
    max-height: 100%;
    width: $logo-width-mobile;

    @include from(lt) {
      width: $logo-width-default;
    }


    svg {
      fill: palette(white);
      height: auto;
      max-width: 100%;
    }
  }

  .desk {
    display: none;
  }

  .meta,
  .region--navigation {
    display: none;

    @include from(sd) {
      display: block;
    }
  }

  @include from(sd) {
    .region--navigation {
      flex: 1 0 auto;

      > ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .desk {
      display: block;
    }

    .meta {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;

      .icon-search {
        height: rem-calc(20);
        width: rem-calc(20);
      }

      .button {
        margin-left: rem-calc(15);
      }
    }
  }


  @include from(ld) {
    .search {
      margin-right: rem-calc(40);
    }
  }
}

.hamburger--spin.is-active--mobile {
  .hamburger-inner {
    &,
    &:before,
    &:after {
      background-color: palette(white);
    }
  }
}