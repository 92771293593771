//
// Standard Text
// --------------------------------------------------

.standard-text {
  .heading {
    @include add-vertical-spacing($spacer-unit * 7, margin, bottom);

    .fancy-font {
      margin-top: $spacer-smallest;
      transform : rotate(-2.5deg);
    }
  }

  .summary{
    margin: 0 auto;

    @include to(pp) {
      text-align: left;
    }

    .content-width--small &{
      max-width: rem-calc(620);
    }

    h3 {
      margin-bottom: $spacer-smallest/2;
      color        : palette(brand);
      line-height  : 0.7em;

    }


  }

  .link-holder{
    @include add-vertical-spacing($spacer-unit--small, margin, top);

    @include to(pp) {
      text-align: left;
    }
  }

  &.content-width {
    &--small {
      .summary {
        max-width: rem-calc(620);
        margin   : 0 auto;
      }
    }
  }

  &--large-image {
    &.content-block {
      @include add-vertical-spacing($spacer-unit * 40, padding, bottom);
      @include from(lp) {
        @include add-vertical-spacing($spacer-unit * 56, padding, bottom);
      }
    }

    .tag-line {
      @include add-vertical-spacing($spacer-unit--mid, margin, bottom);
    }

    .heading {
      @include add-vertical-spacing($spacer-unit--small, margin, bottom);
      .fancy-font {
        line-height: 1.125em;
      }
    }

    .summary{
      @include add-vertical-spacing($spacer-unit--small, margin, top);
    }

    .link-holder{
      @include from(lp) {
        @include add-vertical-spacing($spacer-unit--small, margin, top);
      }
    }
  }

  &--icon-links{

    .icon-links{
      @include add-vertical-spacing($spacer-unit--small, padding, top);
      position: relative;
      z-index : 10;

      &__list{
        display        : flex;
        flex-flow      : row wrap;
        justify-content: center;
        align-items    : stretch;
        margin         : 0 auto;
        padding        : 0;
      }

      &__list-item{
        list-style: none;
        flex      : 0 0 auto;
        @include add-horizontal-spacing($spacer-unit--small);
        @include add-vertical-spacing($spacer-unit--small, padding, top);

      }

      &__list-link {
        display        : flex;
        flex-flow      : column;
        justify-content: flex-start;
        align-items    : center;
        max-width      : rem-calc(200);
        margin         : 0 auto;
        height         : 100%  ;

        cursor: pointer;

        .circle-arrow{
          display: block;
          height : rem-calc(35);
          width  : rem-calc(35);
          margin : auto auto 0;

          .border,
          .arrow{
            fill: $color--text;
          }

          .background{
            fill: transparent;
          }
        }

        &:hover{
          .circle-arrow{

            .background,
            .border{
              fill: palette(brand);
            }

            .arrow{
              fill: palette(white);
            }
          }
        }
      }

      &__list-title {
        display      : block;
        margin-bottom: rem-calc(20);

        color         : $color--text;
        letter-spacing: $letter-spacing--small;
        line-height   : 1.25em;
        font-size     : rem-calc(18);
        font-weight   : $font-weight--bold;
        text-transform: uppercase;
      }

      &__icon {
        display        : flex;
        flex-flow      : row;
        justify-content: center;
        align-items    : center;

        height: rem-calc(90);
        width : rem-calc(90);
        margin: 0 auto rem-calc(16);

        svg {
          display   : block;
          margin    : 0 auto;
          width     : 100%;
          max-height: rem-calc(90);
          max-width : rem-calc(90);

          fill: palette(brand);

          &.icon--water-drop{
            max-height: rem-calc(60);
          }
          &.icon--dinner{
            max-width: rem-calc(70);
          }
        }
      }

    }
  }
}
