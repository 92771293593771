//
// Social
// --------------------------------------------------

.social-media {
  display: flex;
  flex-flow: row nowrap;

  &__link {
    display: block;
    margin-right: rem-calc(24);
    height: rem-calc(32);
    width:  rem-calc(32);

    &:last-child {
      margin-right: 0;
    }

    &--tripadvisor {
      height: rem-calc(40);
      width:  rem-calc(40);      
      line-height: rem-calc(32);
    }
  }

  svg {
    max-height: 100%;
    max-width:  100%;

    transform: scale(1);
    transition-property: transform;
    transition-duration: .25s;
    transition-timing-function: $transitions--easeOutSine;

    &:hover {
      transform: scale(1.25);                
    }
  }
}
