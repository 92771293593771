//
// Buttons
// --------------------------------------------------

.button,
.btn {
  @include button;

  & + .btn{
    margin-top: $spacer-smallest;
    
    @include from(pt) {
      margin-top: 0;
      margin-left: $spacer-smallest;    
    }
  }
}

.button-wrap {
  padding-top: 0;
  text-align: center;
}

.buttons {
  .button-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .button,
  .btn {
    flex: 1 0 auto;
    margin-right:  rem-calc(10);
    margin-bottom: rem-calc(10);
    outline: 0;

    &:last-of-type {
      margin-right: 0;
    }

    @include to(pt) {
      flex: 1 0 135px;
    }
  }
}

.text-link{
  display:         inline-flex;
  flex-flow:       row;
  align-items:     center;

  font-size: 16px;
  font-weight: 700;
  letter-spacing: $letter-spacing--small;
  text-transform: uppercase;

  @include from(lt) {
    font-size: rem-calc(18);
  }
  svg{
    display: block;
    margin: 0 0 0 8px;
    height: 25px;
    width:  25px;
    fill: palette(brand);

    @include from(lt) {
      margin: rem-calc(0 0 0 7.5);
      height: rem-calc(30);
      width:  rem-calc(30);    
    }
  }
}