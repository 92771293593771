//
// Team Members
// --------------------------------------------------

.team-members {
  padding: 0;

  &__inner {
    display: flex;
    flex-flow: row wrap;
  }
}

.team-member {
  overflow: hidden;
  text-align: center;
  margin-bottom: rem-calc(20);
  flex: 0 0 percentage(1/2);

  .large & {
    flex: 0 0 100%;
  }

  @include from(lp) {
    flex: 0 0 percentage(1/3);
  }

  @include from(lt) {
    flex: 0 0 percentage(1/4);

    .large & {
      flex: 0 0 50%;
    }
  }

  &__inner {
    display: block;
  }

  &__image {
    position: relative;
    overflow: hidden;
    margin-bottom: rem-calc(20);
    padding-bottom: 100%;
  }

  &__name,
  &__title {
    margin-bottom: rem-calc(5);
  }

  &__content {
    // @TODO: Replace with placeholder
    @extend .visible-off-canvas;
  }

  &--loader {
    .loader__close {
      color: palette(white);
    }

    .loader__content {
      padding: 0;

      .team-member__header,
      .team-member__content {
        padding-right: rem-calc(20);
        padding-left:  rem-calc(20);

        @include from(lt) {
          padding-right: rem-calc(50);
          padding-left:  rem-calc(50);
        }
      }
    }
  }
}
