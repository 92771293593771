//
// Banner
// --------------------------------------------------

.banner {

  &__image {
    position   : relative;
    padding-top: 65%;

    &::before {
      @include overlay;
    }

    @include from(lt) {
      padding-top: 33%;
    }

    .banner.has-text & {
      padding-top: 45%;

      @include from(pt) {
        position   : absolute;
        top        : 0;
        left       : 0;
        padding-top: 0;
        height     : 100%;
        width      : 100%;
      }
    }
  }

  &__content {
    margin-right  : auto;
    margin-left   : auto;
    padding-top   : rem-calc(70);
    padding-bottom: rem-calc(70);
    max-width     : $max-content-width--wide;

    h2 {
      text-align: center;
    }

    p {
      margin-bottom: rem-calc(20);

      @include from(pt) {
        &:first-of-type {
          @include large-text;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include from(pt) {
      background-color: transparent;
      padding-top     : 0;
      padding-bottom  : 0;
      position        : relative;
      z-index         : 1;

      h2, p {
        color     : palette(white);
        text-align: center;
      }
    }
  }

  &__buttons {
    margin-top: rem-calc(40);
    text-align: center;
  }

  &.has-text {
    @include from(pt) {
      height         : 60vh;
      position       : relative;
      display        : flex;
      justify-content: center;
      align-items    : center;
    }
  }
}
