.rooms{
  background: palette(blue, dark);  

  &.content-block{
    padding-top: 0;
    @include add-horizontal-spacing($spacer-mid);

  }
  
  &__inner{
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     stretch;
    position: relative;
    z-index: 10;
  }

  &__heading{
    @include add-vertical-spacing($spacer-unit--mid, padding, both);
    margin-bottom: 0;

    color: palette(white);
    font-family: $font-family--body;
    line-height: 1em;
    text-align: center;
  }
}

.room{    
  padding: rem-calc(60 10 0);
  flex: 1 1 100%;
  width: 100%;
  display: block;
  min-width: 0;//Weird Fix for firefox & swiper
  min-height: 0;//Weird Fix for firefox & swiper

  &:first-child{
    padding-top: 0;
  }

  @include to(lp) {
    max-width: rem-calc(580);
    margin: 0 auto;
  }
  
  @include from(pt) {
    flex: 0 1 percentage(1/2);
    width: percentage(1/2);    
  
    @include first(2) {
      padding-top: 0;
    }
  }

  @include from(vld) {
    flex: 0 1 percentage(1/3);
    width: percentage(1/3);    
        
    @include first(3) {
      padding-top: 0;
    }
  } 

  .is-oldIE & {      
    
    @include from(pt) {
      flex: 0 1 48%;
      width: 48%;    
    }           

    @include from(vld) {
      flex: 0 1 31.833333333333%;
      width: 31.833333333333%;    
    }           
  }

  &__inner{        
    height: 100%;
    width: 100%;

    display:         flex;
    flex-flow:       column;
    justify-content: flex-start;
    align-items:     flex-start;

  }

  &__slider{
    display: block;
    width: 100%;
  }

  &__images {
    position: relative;
    display: block;
    flex: 0 1 auto;
    width: 100%;
    
    .swiper-navigation {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 50;
      height: rem-calc($spacer-unit--mid);
      padding: rem-calc(0 12);

      display:         flex;
      flex-flow:       row;
      justify-content: center;
      align-items:     center;

      .swiper-prev{
        transform: scaleX(-1);
        margin-right: rem-calc(8);
      }
    }

    .circle-arrow {
      height: rem-calc(36);
      width:  rem-calc(36);
      
      .background {
        fill: transparent;
      }
      
      .border,
      .arrow {
        fill: palette(white);
      }
    }
  }

  &__image {
    img{
      display: block;
      max-width: none;
      width: 100%;
    }
  }

  &__price {
    position: absolute;
    bottom:0;
    left: 0;
    z-index: 50;
    padding: rem-calc(15 25);
    display:         flex;
    flex-flow:       row;
    justify-content: center;
    align-items:     center;

    background: palette(brand, accent);
    color: palette(white);
    letter-spacing: $letter-spacing--small;
    text-transform: uppercase;
    
    &__text,
    &__label {
      display: block;
      line-height: 1em;
    }

    &__text {
      margin-left: rem-calc(5);

      font-weight: 700;
    }
  } 

  &__content {
    padding-top: rem-calc($spacer-unit * 4);
    padding-bottom: rem-calc($spacer-unit * 4);
    @include add-horizontal-spacing($spacer-unit--mid);
    flex: 1 1 auto;
    display:         flex;
    flex-flow:       column;
    justify-content: flex-start;
    align-items:     flex-start;    

    background: palette(white);
    width: 100%;
    max-width: 100%;

    @include from(lp) {
      padding-top: rem-calc($spacer-unit--small);
      padding-bottom: rem-calc($spacer-unit--small);
    }

    &__inner{
      display: block;
      width: 100%;
      max-width: 100%;      
    }
  }

  &__heading {
    @include to(pp) {
      margin-bottom: rem-calc($spacer-unit * 4);
    }
  }

  &__capacity {
    display: block;
    line-height: 1em;    
    margin-bottom: rem-calc(32);

    color: palette(brand);
  }

  &__summary {
    margin-bottom: rem-calc($spacer-unit * 4);
    
    @include from(lp) {
      margin-bottom: $spacer-small;    
    }    
  }

  &__amenities {
    margin-top: auto;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;

    &__heading {
      margin-bottom: $spacer-smallest;
    }

    &__inner {
      display:         flex;
      flex-flow:       row wrap;
      justify-content: center;
      align-items:     center;
    }
  }

  .amenity {
    flex: 1 1 100%;    
    display:         flex;
    flex-flow:       row;
    justify-content: flex-start;
    align-items:     center;    
    border-top: 1px solid rgba(#0E2A34, 0.16);
    
    @include from(lp) {
      flex: 1 1 34%;      
      margin-left: $spacer-smallest;
    }

    @include at(pt) {
      margin-left: rem-calc($spacer-unit * 2);
    }

    &:nth-child(odd) {
      margin-left: 0; 
    }
    
    &:first-child {
      border-top: none;

      & + .amenity{
        @include from(lp) {
          border-top: none;   
        }  
      }
    } 
    
    &__icon,
    &__text {
      display: block;
      line-height: 1em;
      padding: rem-calc(8 0);

    }

    &__icon {      
      margin-right: $spacer-smallest; 
      
      @include at(pt) {
        margin-right: rem-calc($spacer-unit * 2);       
      }
      
      svg {
        display: block;
        fill: palette(brand);
        width:  rem-calc(24);
        height: rem-calc(24);
      }
    }

    &__text {
      font-size: rem-calc(18);
      letter-spacing: $letter-spacing--small;
      text-transform: uppercase;
    }
  }
  
}

