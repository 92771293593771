//
// Slider
// --------------------------------------------------

.content-block.slider {
  &.has-heading {
    @include add-vertical-spacing($spacing-unit--vert--small);

    .swiper-pagination {
      position: static;
      margin-top: rem-calc(20);
    }
  }

  .heading {
    @include add-horizontal-spacing;
    text-align: center;
  }

  img, a {
    display: block;
  }

  img {
    max-width: none;
    width: 100%;
  }

  .swiper-pagination {
    &-bullet {
      background-color: palette(grey, dark);
      height: rem-calc(15);
      width:  rem-calc(15);

      &.swiper-pagination-bullet {
        margin: rem-calc(0 8);
      }

      &-active {
        background-color: palette(brand);
      }
    }
  }

  .swiper-prev,
  .swiper-next {
    fill: palette(white);
    transition: fill .3s ease-out;
    cursor: pointer;
    position: absolute;
    top: 50%;
    line-height: 0;
    z-index: 1;

    &:hover {
      fill: palette(brand);
    }

    &.swiper-button-disabled {
      fill: palette(white);
      opacity: .5;
    }

    .icon {
      height: rem-calc(50);
      width:  rem-calc(50);
    }
  }

  .swiper-prev {
    left: rem-calc(20);
    transform: translate(0, -50%) scaleX(-1);
  }

  .swiper-next {
    right: rem-calc(20);
    transform: translate(0, -50%);
  }
}
