.page-id-2402 {

  @include to(lp) {
    .banner {
      padding-top: 20px;
      padding-bottom: 0;
    }
    .banner__image {
      margin-right: -2.1818181818rem;
      margin-left: -2.1818181818rem;
    }
    .banner__content {
      padding-bottom: 0;
    }
    .two-tile__image {
      min-height: 0;
      padding-bottom: 100%;
    }
  }

}