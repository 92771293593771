.tab {
  h2 {
    text-align: center;
    margin-bottom: $spacer-smallest;

    @include from(lt) {
      margin-top: $spacer-mid;
      margin-bottom: $spacer-mid;
    }
  }

  border-top: 1px solid palette(brand);
  padding: 0;

  @include from(lt) {
    transition: opacity 0.8s ease;
    border-top: 0;

    &.is-inactive {
      opacity: 0;
    }

    &.is-active {
      opacity: 1;
    }
  }


  &:last-child {
    border-bottom: 1px solid palette(brand);
  }
}

.tab__headings--desktop {
  display: none;

  @include from(lt) {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 5px solid palette(brand);
  }


  .tab__heading {
    display: flex;
    justify-content: center;
    border-top: 5px solid transparent;
    transition: color 0.4s ease;

    &.is-active {
      border-top-color: palette(brand);
      background: palette(brand);
      color: palette(white);
    }

    &:hover {
      &:not(.is-active) {
        color: lighten(palette(blue, base), 5%);
      }
    }
  }
}

.tab__heading {
  background: none;
  border: none;
  outline: 0;
  margin: 0;
  padding: $spacer-smallest $spacer-smallest $spacer-smallest 0;
  line-height: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span:first-child {
    font-size: rem-calc(30);
    font-weight: bold;
  }

  .icon {
    fill: #82888a;
    height: 13px;
    width: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s $ease-in-out-quad;
  }

  &-toggle {
    height: rem-calc(18);
    width: rem-calc(18);
    position: relative;

    &::before, &::after {
      content: '';
      background-color: palette(brand);
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      height: 2px;
      width: rem-calc(18);
    }

    &::after {
      height: rem-calc(18);
      width: 2px;
      transition: transform 0.3s $ease-in-out-quad;
    }
  }

  &.tab__headings--mobile {
    @include from(lt) {
      display: none;
    }
  }

  &.is-active &-toggle {
    &::after {
      transform: translate(-50%, -50%) scaleY(0.01);
    }
  }

  em {
    position: absolute;
    top: rem-calc(34);
    font-size: rem-calc(16);
  }
}

.tab__content {
  display: none;
  padding: 0 0 $spacer-smallest;
  text-align: left;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: ease;
  will-change: transform;
  padding: 25px 0;

  @include from(lt) {
    padding: 0;
  }


  p {
    line-height: 1.8;

    &:first-child {
      margin-top: 0;
    }
  }

  &.open-content {
    opacity: 1;
  }

  &.is-active {
    opacity: 1;
    display: block;
  }
}

.img-txt__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include add-vertical-spacing(80, margin, bottom);
}

.img-txt__img, .img-txt__text {
  width: 100%;

  @include from(lt) {
    width: 50%;

    @include add-horizontal-spacing(50, padding);
  }
}

.img-txt__text {
  .btn.btn {
    @include add-vertical-spacing(40, margin, top);

    transition-property: transform, opacity, border-color, background-color, color;
  }
}

.img-txt__img {
  @include add-vertical-spacing(20, margin, bottom);

  &.img-txt__img--right {
    @include from(lt) {
      order: 1;
    }
  }
}
