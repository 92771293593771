//
// Content Columns
// --------------------------------------------------

.content-columns {
  > .heading {
    text-align: center;
  }

  &__container {
    @include from(pt) {
      margin-left:  auto;
      margin-right: auto;
      max-width: $max-content-width--xwide;
    }

    &__inner {
      display: flex;
      margin-left:  rem-calc(-20);
      margin-right: rem-calc(-20);

      @include from(lt) {
        margin-left:  rem-calc(-40);
        margin-right: rem-calc(-40);
      }
    }
  }
}

.content-column {
  margin-bottom: rem-calc(40);
  width: 100%;

  @include from(pt) {
    margin-bottom: 0;
  }

  &__inner {
    margin-left:    auto;
    margin-right:   auto;
    padding-right:  rem-calc(20);
    padding-bottom: rem-calc(30);
    padding-left:   rem-calc(20);
    max-width: rem-calc(500);
    text-align: center;

    @include from(lt) {
      padding-right: rem-calc(40);
      padding-left:  rem-calc(40);
    }
  }

    &__image  {
      display: flex;
      align-items: center;
      flex-flow: column wrap;
      justify-content: center;
      margin-bottom: rem-calc(20);

      img {
        display: block;
      }

      &.svg {
        padding: rem-calc(30);
      }

      svg {
        height: rem-calc(100);
        width:  rem-calc(100);
      }
    }

    &__content {
      margin-bottom: rem-calc(30);
    }

  .content-columns.has-2 & {
    @include from(pt) {
      border: 0;
    }
  }

  .content-columns.has-3 & {
    @include from(pt) {
      &:nth-child(3n) {
        border-right: 0;
      }
    }
  }
}
