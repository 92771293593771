//
// Footer
// --------------------------------------------------

.site-footer {
  @include add-vertical-spacing($spacer-unit * 10, padding, top);

  background-color: palette(blue, dark);
  color: palette(white);
  position: relative;
  z-index: 10;
  overflow: visible;

  .texture--ripped {
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: palette(blue, dark);
      content: '';
      z-index: 10;
    }

    &__image {
      display: block;
      width: 100%;
    }
  }

  &__item {
    position: relative;

    @include add-horizontal-spacing;

    z-index: 10;
  }

  .newsletter {
    @include add-vertical-spacing($spacer-unit * 10, padding, bottom);

    &__inner {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-end;
      max-width: rem-calc($max-content-width--xwide);
      margin: 0 auto;
    }

    &__form {
      flex: 0 1 100%;

      @include to(lp) {
        max-width: rem-calc(530);
        margin: 0 auto;
      }


      @include from(lt) {
        flex: 0 1 80%;
      }


      @include from(sd) {
        flex: 0 1 75%;
      }


      form {
        position: relative;

        @include from(pt) {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: flex-end;
        }
      }

      .gform_heading {
        flex: 0 0 rem-calc(530);
      }

      .gform_body {
        flex: 1 1 50%;
        margin-top: rem-calc($spacer-unit * 4);
        position: relative;
        z-index: 10;

        @include from(pt) {
          margin-left: rem-calc($spacer-unit--small);
          margin-top: 0;
        }


        @include from(lt) {
          margin-left: $spacer-large;
        }
      }

      .gform_title {
        @extend .tag-line;

        color: rgba(#fff, 0.58);
        font-family: $font-family--body;
        line-height: 1em;
        margin-top: 0;
        margin-bottom: rem-calc($spacer-unit--smallest);
      }

      .gform_description {
        display: block;
        margin-top: -0.25em;
        font-family: $font-family--heading;
        font-size: rem-calc(40);
        line-height: 0.85em;
        text-transform: lowercase;
      }

      input[type=email] {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #95989A;
        border-radius: 0;
        box-sizing: border-box;
        color: palette(white);
        font-size: rem-calc(25);
        height: rem-calc(50);
        padding: 0;

        &::placeholder {
          color: #fff;
        }
      }

      .gfield {
        margin-bottom: 0;
      }

      .gform_footer {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 100;
        margin-top: 0;
      }

      .validation_error {
        margin-bottom: 0;
        flex: 1 1 50%;
        text-align: center;

        @include from(pt) {
          margin-left: rem-calc($spacer-unit--small);
        }


        @include from(lt) {
          margin-left: $spacer-large;
        }


        &__wrap {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          right: 0;
          top: 100%;
          z-index: 50;
          margin-bottom: 0;
          width: 100%;

          span {
            display: none;

            @include from(pt) {
              flex: 0 0 rem-calc(530);
              display: block;
            }
          }
        }
      }

      .gfield_error .validation_message {
        right: 50px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22icon-error_outline%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Eerror_outline%3C%2Ftitle%3E%0A%3Cpath%20fill%3D%22%23fff%22%20class%3D%22path1%22%20d%3D%22M12%2020.016c4.406%200%208.016-3.609%208.016-8.016s-3.609-8.016-8.016-8.016-8.016%203.609-8.016%208.016%203.609%208.016%208.016%208.016zM12%202.016c5.531%200%209.984%204.453%209.984%209.984s-4.453%209.984-9.984%209.984-9.984-4.453-9.984-9.984%204.453-9.984%209.984-9.984zM11.016%206.984h1.969v6h-1.969v-6zM11.016%2015h1.969v2.016h-1.969v-2.016z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
      }

      .btn--icon-btn {
        padding: 0;

        .circle-arrow {
          display: block;
          margin-left: 0;
          height: rem-calc(35);
          width: rem-calc(35);

          .background,
          .border {
            fill: palette(brand);
          }

          .arrow {
            fill: palette(white);
          }
        }

        &:hover {
          .circle-arrow {
            .background,
            .border {
              fill: palette(white);
            }

            .arrow {
              fill: palette(brand);
            }
          }
        }
      }
    }

    .social {
      display: none;

      @include from(lt) {
        display: block;
        flex: 0 1 auto;
        padding-bottom: rem-calc($spacer-unit);
      }
    }
  }

  .social-media {
    svg {
      fill: palette(brand);
    }
  }

  .footer__nav {
    @include add-vertical-spacing(80, "margin", "bottom");

    @include from(lt) {
      @include add-vertical-spacing(50, "margin", "bottom");
    }


    ul, li {
      list-style: none;
      padding-left: 0;
    }

    .nav__list {
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex-wrap: wrap;

      @include to(lp) {
        margin: 0 auto;
        max-width: rem-calc(530);
        width: 100%;
      }


      @include from(pt) {
        flex-flow: row;
        justify-content: space-between;
        max-width: rem-calc($max-content-width--xwide);
      }


      > .nav__list-item {
        @include to(lp) {
          &:not(:first-child) {
            @include add-vertical-spacing($spacer-unit--small, margin, top);
          }
        }
      }

      .nav__list-link {
        @extend %dft;

        color: palette(white);

        &:hover {
          color: palette(brand);
        }
      }

      > .menu-item {
        > .nav__list-link {
          span {
            @extend %dft;

            color: palette(white);
            font-size: rem-calc(20);
            font-family: $font-family--body;
            font-weight: $font-weight--bold;
            letter-spacing: $letter-spacing--small;
            text-transform: uppercase;
            margin-bottom: rem-calc(16);

            @include at(pt) {
              font-size: 14px;
            }
          }

          &:not([href]) {
            cursor: text;
          }

          &[href] {
            &:hover {
              span {
                color: palette(brand);
              }
            }
          }
        }
      }
    }
  }

  .contacts-info {
    @include add-vertical-spacing(80);

    border-top: 1px solid rgba(#fff, 0.13);
    border-bottom: 1px solid rgba(#fff, 0.13);

    &__inner {
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      @include from(pt) {
        flex-flow: row;
        justify-content: space-between;
        align-items: stretch;
        max-width: rem-calc($max-content-width--xwide);
      }
    }
  }

  .nav__list > .nav__list-item, .contact-info {
    @include from(pt) {
      flex: 0 1 29%;

      &:nth-child(2) {
        flex: 0 1 34%;
      }
    }


    @include from(ld) {
      flex: 0 1 24.5%;

      &:nth-child(2) {
        flex: 0 1 29%;
      }
    }
  }

  .contact-info {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include to(pt) {
      .btn {
        padding: rem-calc(16 23);
      }
    }


    @include to(lp) {
      display: inline-block;
      margin: 0 auto;
      max-width: rem-calc(530);
      width: 100%;

      @include add-vertical-spacing($spacer-unit--large, margin, top);

      &:first-child {
        margin-top: 0;
      }
    }


    &__heading {
      color: palette(white);
      font-size: rem-calc(20);
      font-family: $font-family--body;
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--small;
      text-transform: uppercase;
      margin-bottom: rem-calc(16);

      @include at(pt) {
        font-size: 14px;
      }
    }

    &__link {
      @extend %dft;

      display: block;
      color: palette(white);
      font-size: rem-calc(24);
      line-height: 1.5em;

      @include at(pt) {
        font-size: 14px;
        white-space: nowrap;
      }


      &:hover {
        color: palette(brand);
      }
    }

    &__address {
      margin-bottom: rem-calc(16);
    }

    &__label {
      font-weight: $font-weight--bold;
      margin-right: rem-calc(2);
      font-size: rem-calc(24);
      line-height: 1.5em;

      @include at(pt) {
        font-size: 14px;
      }
    }

    &__link-handler {
      padding-top: $spacer-smallest;
      margin-bottom: 0;
      margin-top: auto;
    }
  }

  .copyright {
    &__inner {
      max-width: rem-calc($max-content-width--xwide);
      margin: 0 auto;
      padding-top: $spacer-smallest;
      padding-bottom: $spacer-smallest;
      text-align: center;

      @include from(pt) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        text-align: left;
      }
    }

    &__title {
      @include to(lp) {
        display: block;
        margin-bottom: rem-calc(20);
      }
    }

    &__content {
      &,
      .copyright__title,
      a {
        font-size: rem-calc(18);
        color: palette(white);
        line-height: 0.85em;

        @include to(lp) {
          font-size: 18px;
        }
      }

      a {
        @extend %dft;

        display: inline-block;
        padding-left: rem-calc(16);
        margin-left: rem-calc(12);
        border-left: 1px solid #fff;

        &:first-of-type {
          @include to(lp) {
            border-left: none;
            margin-left: 0;
            padding-left: 0;
          }
        }

        &:hover {
          color: palette(brand);
        }
      }
    }

    .leap-link {
      @extend %dft;

      color: palette(white);
      font-size: rem-calc(18);
      line-height: 1em;

      @include to(lp) {
        display: block;
        margin-top: rem-calc($spacer-unit--small);
        font-size: 18px;
      }


      &:hover {
        color: palette(brand);
      }
    }

    .home & {
      @include between(pt, lt) {
        padding-right: 0;

        .leap-link {
          padding-right: rem-calc(65);
        }
      }
    }
  }
}
