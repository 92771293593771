.resturants{

}

.contact-bar{
  text-align: center;
  
  &__inner{    
    @include add-horizontal-spacing(56);
    @include add-vertical-spacing($spacer-unit--small);
    display: inline-block;
    text-align: left;

    @include from(lp) {
      display:         flex;
      flex-flow:       row;
      justify-content: space-between;
      align-items:     flex-start;
    }
  }



  .social-media{
    justify-content: flex-end;
    align-items: center;
    margin-top: rem-calc($spacer-unit);
    
    h5 {
      margin-bottom: 0;
      line-height: 1em;
      margin-right: rem-calc($spacer-unit);
      
    }    

    svg {
      height: rem-calc(25);
      width: rem-calc(25);      
    }
  }
}

.hours{

  &__list{
    padding: rem-calc(0 0 0 30);
    margin: 0;
  }

  &__list-item{
    padding: 0;
    margin:  0;
    list-style: none;
    font-size: rem-calc(21);


    &--heading{      
      position: relative;
      
      font-size: rem-calc(18);
      font-weight: $font-weight--bold;
      text-transform: uppercase;

      svg{
        display: block;
        position: absolute;
        top: 50%;
        right: 100%;
        margin-right: rem-calc(10);
        margin-top: rem-calc(-11);
        height: rem-calc(22);
        width: rem-calc(22);

        fill: palette(brand);
      }
    }
  }

  &__label{
    margin-right: rem-calc(5);
  }
}

.contact-details{
  text-align: right;
  
  @include to(pp) {
    display: inline-block;
    margin-top: rem-calc(24);
  }

  &__phone{
    margin-top: rem-calc(8);

    &,
    a{
      color: $color--text;
    }
  }
}