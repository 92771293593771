.ocean-wrap {
  position: relative;
  overflow: hidden;  
  
  .bg-img{
    z-index: 1;
  }
  
  > .texture{
    bottom: 50%;
    z-index: 5;
    //background: #000;
    .texture__image{
      background-image: url(../images/textures/TRM-Home-Water-Texture.png);
      transform: translateY(-10%);
    }
  } 
  
  .content-block{
    z-index: 10;
  }
}

.ocean-bg-anchor{
  position: relative;
  background: #061624;

  .ocean-bg{
    top: -190px;
    bottom: 0;
    height: auto;
    @include from(pt) {
      top: -15vw;
    }
    @include from(lt) {
      top: -20vw;
    } 
    
    @include to(lp) {
      background-position: 0px top !important;
      background-size: 1000px auto !important;
    }
  }
}

.home {

  .ocean-wrap .two-tile--icon-list {
    
    &.content-block {
      @include add-vertical-spacing($spacer-unit * 16, padding, bottom);
      
      @include from(lp) {
        @include add-vertical-spacing($spacer-unit * 22, padding, bottom);
      }
      
      @include from(sd) {
        @include add-vertical-spacing($spacer-unit * 43, padding, bottom);
      }
    }

    .tag-line.center-tag-line {
      display: block;     
      line-height: 1em;
      margin-bottom: 0;
      text-align: left;
      
      @include from(lp) {
        text-align: center;
      }
      
      @include add-vertical-spacing($spacer-unit * 9, padding, top);
      @include add-vertical-spacing($spacer-unit * 22, padding, bottom);
       
      @include from(pt) {
        @include add-vertical-spacing($spacer-unit--large, padding, top);
        @include add-vertical-spacing($spacer-unit * 20, padding, bottom);
      }

      @include from(lt) {
        @include add-vertical-spacing($spacer-unit--large, padding, top);
        @include add-vertical-spacing($spacer-unit * 27, padding, bottom);
      }
      
      @include from(ld) {
        @include add-vertical-spacing($spacer-unit * 17, padding, top);
        @include add-vertical-spacing($spacer-unit * 27, padding, bottom);
      }


    }

    .heading,
    p,
    li {
      color: palette(white);
    }

    .heading {
      .fancy-font {
        margin-top: rem-calc($spacer-unit * 2);
        
        @include to(lp) {
          font-size: 96px;            
          margin-top: rem-calc($spacer-unit * 4);
        }

        @include breakpoint($tiny-phone) {
          font-size: 80px;            
          margin-top: rem-calc($spacer-unit--smallest);
        }
      }
    }

    .icon-list__link {
      color: palette(white);
    }
  }



  .standard-text--large-image.content-block {

    .heading {
      .fancy-font {
        margin-top: 0;
      }
    }

    @include to(pp) {
      background: #BFCBD5;

      .bg-img {
        background-position: bottom center !important;
        background-size: 200% auto !important;
        top:50%;
        bottom: 0;
        height: auto;

        &:before{
          content: '';
          background-image: linear-gradient(to top, rgba(#BFCBD5,0), rgba(#BFCBD5,1));                  
          position: absolute; 
          top: 0;
          left: 0;
          width: 100%;      
          z-index: 10;      
          
          @include responsive-vert-unit(height, 160);
          @include responsive-vert-unit(margin-top, 0);
        }

      }

      .standard-text__content,
      .link-handler{
        text-align: left !important;
      }
    }
  }
}