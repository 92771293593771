//
// Google Maps
// --------------------------------------------------

.gmap-info-box,
.gm-style-iw {
  h4 {
    margin-bottom: rem-calc(10) !important;
    text-transform: uppercase;
  }

  .info-box-address {
    display: block;
    letter-spacing: $letter-spacing--medium;
    max-width: rem-calc(330);
    line-height: 1.2;
  }
}

.map {
  display: flex;
  align-items: stretch;
  margin-top: rem-calc(10);

  &__legend {
    flex: 0 1 25%;
    background-color: palette(brand, accent);
    color: palette(white);

    @include from(pt) {
      padding-right: rem-calc(20);
      padding-left:  rem-calc(20);
    }

    ul {
      line-height: 1.2;
      margin:  0;
      padding: 0;
      list-style-type: none;
      //font-size: rem-calc(12);
      @include responsive-font(2vw, 12px, 22px, 22px);
    }

    &__pin {
      cursor: pointer;
      margin: 0;
      padding: rem-calc(5 5);
      transition: all .5s $ease-out-circ;

      &.is-active {
        background-color: palette(brand);
        color: palette(white);
      }
    }
  }

  &__handler {
    flex: 1 0 75%;
    width: 75%;
  }

  &__canvas {    
    height:    100%;
    width:     100%;
  }
}
