@mixin hide-menu {
  transform: scale(0);
  background: palette(blue, dark);  
  padding-bottom: 55px;     

  @include from(lt) {
    padding-bottom: rem-calc(55);   
  }
}

@mixin show-trigger {
  opacity: 1;
  svg {
    transform: rotate(135deg);      
  }
}

.home{

  &__page-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 400;
    padding: 0;
    margin:  0;
    text-align: right;
    display: block;

    &__list {
      
      position: relative;
      z-index: 5;
      padding-left: 0;      
      padding: 25px;
      
      border-radius: 0px;
      transition-property: all;
      transition-duration: .25s;
      transition-timing-function: linear;
      
      transform: scale(1); 
      transform-origin: 100% 100%;
      
      @include from(pt) {
        padding: rem-calc(25 25 25);
      }

      @include to(lp) {
        @include hide-menu; 

        .show &{
          transform: scale(1);  
        }       
      }

      .hidden-menu & {
        @include hide-menu;        
      }      

      .hidden-menu.show & {
        transform: scale(1);
      }

    }

    

    &__list-item {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: left;
    }

    &__list-link {


      display: block;          
      padding: 7.5px 30px 7.5px 0;
      position: relative;

      color: palette(white);
      
      font-size: rem-calc(18);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: $letter-spacing--small;
      line-height: 1em;
      text-transform: uppercase;     

      transition-property: all;
      transition-duration: .125s;
      transition-timing-function: linear;   

      @include from(pt) {
        font-size: rem-calc(18);
        padding: rem-calc(7.5 30 7.5 0);
      }
      
      &:before,
      &:after {
        content: '';
        display: block;        
        height: 15px;
        width:  15px;        
        position: absolute;
        top: 50%;
        right: 0;        
        margin-top: -7.5px;
        border-radius: 1000px;

        @include from(pt) {
          height: rem-calc(15);
          width:  rem-calc(15);        
          margin-top: rem-calc(-7.5);
        }
      }

      &:before {
        border: 2px solid palette(white);
      }

      &:after { 
        background: palette(white);
        display: block;
        transform: scale(0);
        transition-property: transform;
        transition-duration: .125s;
        transition-timing-function: linear;        
      }

      .hidden-menu & {
        border-bottom: 1px solid rgba(#fff, 0.16);
        padding: 15px 30px 15px 0px;

        @include from(pt) {
          padding: rem-calc(15 30 15 0);
        }
      }
      
      @include to(lp) {
        border-bottom: 1px solid rgba(#fff, 0.16);
        padding: 15px 30px 15px 0px;

        @include from(pt) {
          padding: rem-calc(15 30 15 0);
        }
      }

      &.section-triggered {
        &:after{          
          transform: scale(1);   
        }
      }     
    }
    
    &__trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row;      
      padding: 20px 25px;
      position: absolute;
      bottom:0;
      right:0;
      z-index: 10;
      opacity: 0; 
      background: palette(blue, dark);    

      transition-property: all;
      transition-duration: .25s;
      transition-timing-function: linear;        
      
      @include from(pt) {
        padding: rem-calc(20 25);
      }

      svg {

        transition-property: transform;
        transition-duration: .25s;
        transition-timing-function: linear;        
        display: block;
        transform: rotate(45deg);      
        
        height: 15px;
        width:  15px;        
        fill: palette(white);

        @include from(pt) {
          height: rem-calc(15);
          width:  rem-calc(15);        
        }
      }


      .hidden-menu & {
        @include show-trigger;
      }

      @include to(lp) {
        @include show-trigger;

        .show & {
          svg {
            transform: rotate(0deg);      
          } 
        }
      }

      .hidden-menu.show & {        
        svg {
          transform: rotate(0deg);      
        } 
      }
    }
  }
}