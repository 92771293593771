.logo-grid {
  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: center;
    align-items:     center;
    margin-bottom: -2rem;
  }

  &__logo {
    display: block;
    padding: 0 1rem 2rem;
    flex: 0 0 rem-calc(250);

    @include from(lp) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
}
