//
// Listing
// --------------------------------------------------

.listing {
  @include pie-clearfix;
  color: $color--text;
  display: block;
  margin-bottom: rem-calc(20);
  padding: rem-calc(0 10);
  transition: opacity .3s ease-out, transform .3s ease-out;
  width: 100%;

  @include to(pt) {
    max-width: rem-calc(580);
    margin: 0 auto;
  }

  @include from(lt) {
    flex: 0 1 33.333333333%;
  }

  &.lazyload {
    opacity: 0;
    transform: translateY(5%);
  }

  &.lazyloaded {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity .3s ease-out, transform .4s ease-out;
  }

  &__inner {
    display:         flex;
    flex-flow:       column;
    justify-content: flex-start;
    align-items:     flex-start;
    height: 100%;

    background-color: palette(white);
    
    &,
    &:hover {
      color: $color--text;
    }
  }

  &__image {
    overflow: hidden;
    position: relative;
    height: 35vw;
    width: 100%;
    flex: 0 0 auto;

    @include from(lt) {
      height: 19.688vw;
    }

    img {
      display: block;
      transition: transform .2s ease-in-out;
      transition-delay: .1s;
      height: auto;
      width:  100%;
    }

    &::after {
      background-color: transparent;
      content: '';
      transition: all .4s ease-in-out;
      position: absolute;
      top:  0;
      left: 0;
      height: 100%;
      width:  100%;
    }
  }
  .meta {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     center;

    margin-bottom: $spacer-smallest;
  }

  time {
    display: block;
    color: palette(brand);
    font-size: rem-calc(18);
    font-weight: $font-weight--bold;
    line-height: 1em;
    letter-spacing: $letter-spacing--small;
    text-transform: uppercase;
  }

  &__title {
    line-height: 0.75em;
    margin-bottom: $spacer-smallest;

    @include between(lt, sd) {
      font-size: rem-calc(36);
    }

    @include between(d, ld) {
      font-size: rem-calc(40);
    }
  }

  &__content {
    display:         flex;
    flex-flow:       column;
    justify-content: flex-start;
    align-items:     flex-start;
    flex: 1 1 auto;
    padding-top: $spacer-small;
    padding-bottom: 0;
    @include add-horizontal-spacing($spacer-unit--mid);
  }

  &__summary {
    margin-bottom: rem-calc($spacer-unit * 3);
    @include between(lt, sd) {
      font-size: rem-calc(18);
    }

    @include between(d, ld) {
      font-size: rem-calc(22);
    }
  }

  &__link {
    display: block;
    padding: rem-calc(($spacer-unit * 3) 0 $spacer-unit--small);

    margin-top: auto;
    margin-bottom: 0;
    border-top: 1px solid rgba(#000, 0.1);
    width: 100%;;

    line-height: 1em;
    .text-link {
      color: palette(brand);
    }
  }

  &:hover &__image {
    img {
      transform: scale(1.03);
      transition: transform .3s ease-in-out;
    }

    &::after {
      background-color: rgba(palette(brand), .5);
    }
  }
}