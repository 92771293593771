//
// Search
// --------------------------------------------------

.search-feature {
  @include clearfix;
  background: rgba(palette(black), .8);
  display: none;
  position: fixed;
  top: 0;
  opacity: 0;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  @include add-horizontal-spacing;
  height: 100%;
  width:  100%;
  z-index: 300;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width:  100%;
  }

  .nocsstransform & {
    left: -200%;
  }

  &__form {
    position: relative;
    margin-right: auto;
    margin-left:  auto;
    max-width: rem-calc(700);
    height: rem-calc(80);
    opacity: 0;
  }

  &__search[type='search'] {
    appearance: none;
    border: 0;
    border-bottom: 3px solid white;
    border-radius: 0;
    box-sizing: border-box;
    background: transparent;
    color: white;
    box-shadow: none;
    display: inline-block;
    margin: 0;
    padding: rem-calc(20 50 20 15);
    float: left;
    font-family: $font-family--heading;
    font-size: rem-calc(30);
    font-weight: 700;
    outline: 0;
    height: rem-calc(80);
    width: 100%;

    @include from(pt) {
      font-size: rem-calc(50px);
    }
  }

  &__submit,
  &__close {
    appearance: none;
    border: 0;
    display: block;
    margin:  0;
    padding: 0;
    font-size: rem-calc(45);
    height: rem-calc(40);
    outline: none;

    i {
      &::before {
        display: block;
        width: 100%;
      }
    }
  }

  &__submit {
    background: transparent;
    color: #FFF;
    position: absolute;
    right: 0;
    font-size: rem-calc(52);
    height: 100%;
  }

  &__close {
    background: transparent;
    color: palette(white);
    position: absolute;
    top: rem-calc(20);
    right: 0;
    transform: translateX(-50%);
    width: rem-calc(50);
  }
}

// Search results
main {
  .search {
    > header {
      text-align: center;
      padding-top:    rem-calc(35);
      padding-bottom: rem-calc(35);

      h3 {
        font-size: rem-calc(20);
      }
    }
  }
}
