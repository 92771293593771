//
// Accordion
// --------------------------------------------------
.accordion-block {
  
  &__heading.heading {
    margin-bottom: $spacer-smallest;
  }

  &__content{
    margin-top: $spacer-small;
    margin-bottom: $spacer-small;
  }

}

.accordion {
  width: 100%;

  &__handler {
    border-bottom: 1px solid $color--border;
  }

  &__container {
    border-top: 1px solid $color--border;
    padding: 0;

    &:last-child{
      border-bottom: 1px solid $color--border;
    }
  }

  &__heading {
    @extend %list-link-style;
    cursor: pointer;
    margin:  0;
    padding: $spacer-smallest $spacer-smallest $spacer-smallest 0;
    line-height: 1em;
    position: relative;
    
    .icon {
      fill: #82888a;
      height: 13px;
      width:  14px;
      position: absolute;
      right: 10px;
      top:   50%;
      transform: translateY(-50%) rotate(180deg);
      transition: transform .3s $ease-in-out-quad;
    }

    &__toggle {
      position: absolute;
      top: 50%;
      right: rem-calc(10);
      transform: translate(0, -50%);
      height: rem-calc(18);
      width:  rem-calc(18);

      &::before, &::after {
        content: '';
        background-color: palette(brand);
        display: block;
        position: absolute;
        top:  50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        height: 2px;
        width: rem-calc(18);
      }

      &::after {
        height: rem-calc(18);
        width:   2px;
        transition: transform .3s $ease-in-out-quad;
      }
    }

    &.is-active &__toggle {
      &::after {
        transform: translate(-50%, -50%) scaleY(0.01);
      }
    }

    em {
      position: absolute;
      top: rem-calc(34);
      font-size: rem-calc(16);
    }
  }

  &__content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: 0 0 $spacer-smallest;
    @include add-horizontal-spacing(72, padding, left);
    
    text-align: left;
    transform: scale(.9);
    transition: transform .3s ease;
    will-change: transform;    

    p {
      line-height: 1.8;


      &:first-child {
        margin-top: 0;        
      }
    }

    &.open-content {
      transform: scale(1);
    }
  }
}
