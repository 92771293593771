.post {
  &__inner {
    @include add-vertical-spacing($spacing-unit--vert, padding, bottom);
    @include add-horizontal-spacing;
  }

  &__header {
    text-align: left;
    margin: rem-calc(0);
    margin-top: rem-calc(10);
  }

  &__heading {
    &.beta {
      margin-bottom: rem-calc(10);
    }
  }

  .meta {
    margin: rem-calc(0 0 20);
    line-height: 1em;
    font-size: rem-calc(14);
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  &__featured-image {
    line-height: 0;
    margin-bottom: rem-calc(60);
  }
}