//
// Mobile Navigation
// --------------------------------------------------

.nav--mobile {
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  .nav__padding-wrap {
    @include add-horizontal-spacing;
    @include add-vertical-spacing(120, "margin", "top");

    width: 100%;
  }

  &--primary {
    display: block;
    background: palette(blue, dark);
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    -webkit-overflow-scrolling: touch;
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(50);
    transform: translateX(100%);

    //transition: transform .4s $ease-out-cubic;
    width: 100%;
    height: 100%;
    z-index: 1001;

    @include from(lp) {
      width: 60%;
    }


    @include from(lt) {
      width: 440px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
    }


    a {
      //overflow: hidden;
      //text-overflow: ellipsis;
      display: block;
      line-height: 0.7em;
      margin-top: -0.2em;
      transform: translateZ(0);
      transition: transform .3s, opacity .3s;
      will-change: transform, opacity;
    }

    .no-js & {
      position: relative;
      height: auto;
      width: 100%;
      overflow: visible;
      visibility: visible;
      z-index: 2;
    }
  }

  &__contact {
    padding-top: rem-calc(30);

    .contact-info {
      text-align: left;

      h4 {
        font-size: rem-calc(18);
        letter-spacing: 0;
      }

      > ul {
        margin-bottom: 0;

        li {
          padding: rem-calc(10 0);
        }
      }
    }
  }

  &__buttons {
    padding-top: rem-calc(60);
    text-align: center;

    .button {
      margin-bottom: rem-calc(10);
      width: 90%;
    }
  }

  &__search {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
    width: 100%;

    input[type='search'] {
      outline: 0;
      width: 75%;

      &::placeholder {
        color: #999;
      }
    }

    button[type='submit'] {
      background-color: palette(brand);
      color: #fff;
      font-family: $font-family--heading;
      font-size: rem-calc(15);
      font-weight: 700;
      text-transform: uppercase;
    }

    .input-group {
      position: relative;

      .input-group-btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 25%;
      }
    }
  }

  &__social {
    margin-top: rem-calc(20);

    a {
      @extend %default-transition;

      color: palette(brand);
      border: 0;
      float: left;
      font-size: rem-calc(18);
      margin: rem-calc(0 0 0 10);
      padding: 0;
      width: rem-calc(20);

      &:hover {
        color: palette(brand);
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

// Pretty facking menu
// @TODO: Update class structure to indicate mobile variant
.menu {
  margin-top: rem-calc(80);
  min-height: 305px;
  background: transparent;
  position: relative;
  right: 0;
  z-index: 20000;

  &__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &__level {
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    list-style-type: none;
    height: auto;
    visibility: hidden;
    margin: 0;
    padding: 0;
    width: 100%;

    &--current {
      visibility: visible;
    }
  }

  &__item {
    display: block;
    margin: 0;

    .animate-outToRight & {
      animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    }

    .animate-outToLeft & {
      animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    }

    .animate-inFromLeft & {
      animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    }

    .animate-inFromRight & {
      animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    }
  }

  &__link {
    display: block;
    color: palette(white);
    border-bottom: 1px solid rgba(palette(white), 0.16);
    position: relative;
    padding: rem-calc(24 0);
    font-family: $font-family--heading;
    font-size: 30px;
    font-weight: 400;
    text-transform: lowercase;
    transition: color 0.1s;

    &[data-submenu]::after {
      @extend %right;

      color: palette(brand);
      position: absolute;
      top: 50%;
      right: 0;
      line-height: 1em;
      font-size: rem-calc(36);
      margin-top: rem-calc(-12);
      padding: 0;

      @include icon;
    }

    &:hover,
    &[data-submenu]:hover::after {
      color: palette(brand);
    }
  }

  &__back {
    background: none;
    cursor: pointer;
    border: 0;
    bottom: 100%;
    left: -6px;
    display: block;
    color: palette(brand);
    font-size: rem-calc(18);
    font-weight: $font-weight--bold;
    line-height: 1em;
    letter-spacing: $letter-spacing--small;
    margin: rem-calc(0 0 0);
    padding: rem-calc(12 0);
    position: absolute;
    opacity: 1;
    transform: translateX(0px);
    transition: transform 0.25s $ease-out-cubic, opacity 0.25s $ease-out-cubic;
    text-transform: uppercase;
    z-index: 100;

    &:hover,
    &:focus {
      color: palette(brand);
      outline: none;
    }

    &.is-hidden {
      pointer-events: none;
      opacity: 0;
      transform: translateX(20px);
    }
  }

  &__breadcrumbs {
    position: absolute;
    bottom: 100%;
    left: 0;
    font-size: rem-calc(10);
    line-height: 1em;
    padding: rem-calc(30 0 0);
    height: rem-calc(40);

    a {
      cursor: pointer;
      display: inline-block;
      color: palette(brand);
      vertical-align: top;
      font-size: 10px;
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--large;
      line-height: 1em;
      text-transform: uppercase;
      margin-right: rem-calc(3);
      padding-right: rem-calc(13);
      position: relative;

      &,
      &:hover {
        color: palette(brand);
      }

      &:last-child {
        pointer-events: none;
      }

      &:not(:last-child) {
        &::after {
          @extend %right;

          @include icon;

          color: palette(brand);
          display: inline-block;
          line-height: rem-calc(10);
          padding: 0;
          position: absolute;
          top: 1px;
          right: 0;
        }

        &:hover::after {
          color: #33353e;
        }
      }
    }

    .breadcrumb-link {
      opacity: 1;
      transition-property: opacity transform;
      transition-duration: 0.25s;
      transition-timing-function: easeOutCubic;
      transform: translateX(0px);

      &.not-visible {
        opacity: 0;
        transform: translateX(20px);

        &:first-child {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
  }
}

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}

.mobile-footer {
  @include add-vertical-spacing($spacer-unit * 8, padding, top);

  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .nav__padding-wrap {
    margin-bottom: auto;
    text-align: center;
  }

  .nav {
    &__list {
      @include add-horizontal-spacing($spacer-unit--large, padding, left);
      @include add-horizontal-spacing($spacer-unit--smallest, padding, right);

      border-top: 1px solid rgba(palette(white), 0.16);
      margin-top: $spacer-small;
      padding-top: $spacer-small;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }

    &__list-item {
      display: block;
      flex: 1 1 100%;
      line-height: 0.7em;
      margin-bottom: 15px;
    }

    &__list-link {
      display: inline-block;
      color: palette(white);
      font-size: 16px;
      font-weight: $font-weight--regular;
      line-height: 0.7em;
      letter-spacing: 0;
      text-transform: none;
      margin-left: 8px;
      padding-left: 12px;
      border-left: 1px solid palette(white);

      &:first-child {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  .social {
    @include add-horizontal-spacing;

    width: 100%;

    svg {
      fill: palette(brand);
    }
  }

  .button {
    display: block;
    padding: 17.5px 50px;
    font-size: 16px;

    @include from(sd) {
      display: none;
    }
  }
}
