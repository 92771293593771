%list-container {
  overflow: hidden;
  padding: rem-calc(10 0);
}

.entry-content,
.page__content,
.component__content,
.constrained {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: rem-calc($max-content-width);
  z-index: 10;
}

.entry-content,
.page__content,
.component__content {
  @include pie-clearfix;

  .content-width--wide & {
    max-width: rem-calc($max-content-width--wide);
  }

  &.full-width {
    @include add-horizontal-spacing;

    max-width: none;
  }

  .link-handler {
    margin-top: rem-calc(40);
    text-align: center;

    .btn {
      &:not(:first-child) {
        margin-left: rem-calc(15);
      }
    }
  }
}

.content-block {
  @include add-vertical-spacing(90);
  @include add-horizontal-spacing;

  position: relative;
  overflow: visible;

  &.remove-padding {
    @include remove-vertical-spacing-top;
  }

  &.background-color--light.padding-collapse + .background-color--light.padding-collapse,
  &.background-color--dark.padding-collapse + .background-color--dark.padding-collapse {
    @include remove-vertical-spacing-top;
  }

  &.has-collapse-below {
    @include add-vertical-spacing(168, padding, bottom);

    @include from(lp) {
      @include add-vertical-spacing(192, padding, bottom);
    }


    @include from(ld) {
      @include add-vertical-spacing(272, padding, bottom);
    }
  }

  &.text-aligned--left {
    text-align: left;
  }

  &.text-aligned--center {
    text-align: center;
  }

  &.text-aligned--right {
    text-align: right;
  }

  &.two-tile,
  &.slider,
  &.image-grid,
  &.map {
    padding: 0;
  }
}

.main,
.sidebar {
  .home &,
  .page & {
    margin-top: 0;
    margin-bottom: 0;
  }
}

section {
  &.background-color--dark,
  &.dark {
    background-color: #f7f8f8;
  }

  .entry-content {
    position: relative;
  }

  &.background-color--dark {
    header, .heading {
      // color: palette(white);
    }
  }
}
