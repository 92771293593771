//
// Hero
// --------------------------------------------------

$home-hero-height: 100vh;
$hero-internal-height: calc(100vh - #{rem-calc(204)});

.no-hero {
  .float-wrapper,
  .region--header {
    position: relative;
  }
}

.hero {
  background-color: palette(brand, accent);
  color: palette(white);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-height: 650px;

  .bg-img {
    &:before {
      content: '';
      background-image: linear-gradient(to top, rgba(#0C282F, 1), rgba(#0C282F, 0));
      opacity: 0.43;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  .hero__img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    img {
      object-fit: cover;
      height: auto;
      width:100%;
    }
  }

  .wrap {
    height: 36%;
    position: absolute;
    bottom: 0;
    text-align: left;
    z-index: 1;
  }

  &__inner{
    @include add-horizontal-spacing();
    position: relative;
    z-index: 300;
    text-align: center;
    width: 100%;
  }

  &__tag-line {
    display: block;
    letter-spacing: $letter-spacing--small;
    font-size: rem-calc(18);
    font-weight: 700;
    text-transform: uppercase;
  }

  &--home {
    //height: 600px;
    //@include from(lp) {
      min-height: 600px;
      height: calc(#{$home-hero-height});
    //}

    .nav-in-hero & {
      height: $home-hero-height;

      @include from(sd) {
        height: $home-hero-height;
      }
    }

    .hero {
      &__heading {
        margin-bottom: 0;
      }

      &__icon {
        display: block;
        margin: rem-calc($spacer-unit) auto rem-calc($spacer-unit--smallest);
      }

      &__tag-line {
        margin-top: rem-calc(25);
        line-height: 1em;

        @include to(pp) {
          line-height: 1.5em;
          max-width: 250px;
          margin: 0 auto;
        }
      }
    }
  }

  &--internal {
    height: rem-calc(600);

    @include from(lt) {
      height: $hero-internal-height;
    }


    .hero {
      &__heading {
        @include to(pp) {
          .fancy-font {
            margin-top: $spacer-smallest;
          }
        }
      }
    }
  }

  &--restaurant {
    height: rem-calc(600);

    @include from(lt) {
      height: $hero-internal-height;
    }


    .hero__logo {
      display: block;
      margin: 0 auto;
      width: 65%;
      max-width: rem-calc(500);

      @include to(pt) {
        padding-top: $header-height-mobile / 2;
      }

      @include from(lp) {
        width: 35%;
      }

      &.logo-1909 {
        max-width: rem-calc(410);

        @include from(pt) {
          width: 30%;
        }


        @include from(lt) {
          width: 25%;
        }
      }
    }
  }
}
