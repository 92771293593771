//
// Variables
// --------------------------------------------------

// Leap Options
$nav-in-hero: true;

//== Palette
// --------------------------------------------------

// Base colours
$_color-base-grey: #28292B;
$_color-base-brand: #DAA000;

// Colour Palette
$palettes: (white: (base: #FFFFFF), black: (base: #000000, brand: darken($_color-base-brand, 54%)), red: (base: #cc0000), grey: (base: $_color-base-grey, light: #95989A, lighter: #F0F2F4, dark: #4D4D4D, darker: #262626, darkest: #1F1F1F), blue: (base: #244C5A, dark: #0E2A34), brand: (base: $_color-base-brand, accent: #244C5A, bright: lighten($_color-base-brand, 32.5%), complementary: complementary($_color-base-brand), secondary: secondary($_color-base-brand), tertiary: tertiary($_color-base-brand)));

//== Colours
// --------------------------------------------------

// Typography
$color--text: palette(blue, dark);
$color--hover: palette(brand, accent);
$color--border: #C2C2C2;
$color--link: palette(brand);

//== Typography
// --------------------------------------------------

// Weights
$font-weight--thin: 100;
$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--medium: 500;
$font-weight--semi-bold: 600;
$font-weight--bold: 700;
$font-weight--extra-bold: 800;
$font-weight--black: 900;

$font-weight--body: $font-weight--regular;
$font-weight--heading: $font-weight--regular;

// Families
$_font-family--brandon-grotesque: "brandon-grotesque", sans-serif;

$_font-family--wayfaring_font_two: "Wayfaring Font Two", script;
$_font-family--trade_supply: "Trade Supply", sans-serif;
$_font-family--trade_supply_textured: "Trade Supply Textured", sans-serif;

$font-family--body: $_font-family--brandon-grotesque;
$font-family--heading: $_font-family--trade_supply_textured;
$font-family--special: $_font-family--wayfaring_font_two;

// Sizes
$font-size--base: 22px;
$font-size--large: 33px;
$line-height--base: 1.8;
$line-height--condensed: 1.4;

$font-size--p: $font-size--base;
$font-size--h1: 123px;
$font-size--h2: 70px;
$font-size--h3: 50px;
$font-size--h4: 40px;
$font-size--h5: 32px;
$font-size--h6: 18px;

$font-size--small: 10px;

$paragraph-spacing: 30px;

$spacer-unit: 8;

$spacer-unit--smallest: $spacer-unit * 3; //24
$spacer-unit--small: $spacer-unit * 6; //48
$spacer-unit--mid: $spacer-unit * 8; //64
$spacer-unit--large: $spacer-unit * 12; //96
// Spacing
$spacer-smallest: rem-calc($spacer-unit--smallest);
$spacer-small: rem-calc($spacer-unit--small);
$spacer-mid: rem-calc($spacer-unit--mid);
$spacer-large: rem-calc($spacer-unit--large);

$letter-spacing--base: normal;

$letter-spacing--small: 0.1em;
$letter-spacing--medium: 0.2em;
$letter-spacing--large: 0.3em;

//== Transitions
// --------------------------------------------------

$transitions--easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$transitions--easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$transitions--easeOutBack: cubic-bezier(0.04, 0.29, 0.3, 1.35);
$transitions--base: $transitions--easeInOutQuad;

//== Hamburger
// --------------------------------------------------
$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-width: 35px;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-border-radius: 4px;
$hamburger-layer-color: palette(blue, dark);
$hamburger-hover-opacity: 0.7;
$hamburger-hover-transition-duration: 0.15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);

// Remove or comment out the hamburger types you don’t want
// or need, so they get excluded from the compiled CSS.
// $hamburger-types: (
//   3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   slider,
//   slider-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
//   spin,
//   spin-r,
//   squeeze,
//   vortex,
//   vortex-r
// );
$hamburger-type: vortex;

//== Grid
// --------------------------------------------------

// Set consistent vertical and horizontal spacing units.
$spacing-unit--vert: 144px !default;
$spacing-mod--vert--tab: 1 !default;
$spacing-mod--vert--mobile: 0.8 !default;
$spacing-unit--vert--small: $spacing-unit--vert / 2;

$spacing-unit--horz: 96px !default;
$spacing-mod--horz--tab: 0.8 !default;
$spacing-mod--horz--mobile: 0.5 !default;

$spacing-unit--component: 48px !default;
$spacing-mod--component--tab: 1 !default;
$spacing-mod--component--mobile: 1 !default;

$header-reduction--mobile: 0.8 !default;

$max-content-width: 800px;
$max-content-width--wide: 900px;
$max-content-width--xwide: 1400px;

$vert-typographic-spacing: 45px;

$noquery: ".lt-ie9";

$grid-padding: 2rem;
$slicer-anti-overlap-corrections: -1px;

// Breakpoints
$slicer-breakpoints: 0 550px 768px 960px 1025px 1250px 1440px 1725px 1921px;

//                    └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘
// Slice numbers:          1           2           3           4           5           6           7           8           9
$slicer-breakpoint-names: "pp" "lp" "pt" "lt" "sd" "d" "ld" "vld" "xld";

$tiny-phone: max-width 374px;

$header-height-default: rem-calc(180);
$header-height-mobile: rem-calc(150);
$header-height-float: 1.2;
$header-background: palette(white);

$logo-width-default: rem-calc(160);
$logo-width-mobile: rem-calc(120);
