//
// Tiles
// --------------------------------------------------

.two-tile {
  overflow: hidden;

  @include from(pt) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .add-padding {
    overflow: hidden;    
  }
  
  &__image {
    text-align: center;
    overflow: hidden;
    position: relative;
    min-height: 50vw;
    width:100%;

    @include from(pt) {
      display: flex;
      align-items: center;
      margin-bottom:  0;
      padding-bottom: 0;
      width: 50%;

      img,
      picture {
        display: block;
        width: 100%;
      }
    }

    @include from(ld) {
      min-height: rem-calc(780);
    }
  }
  
  &__content {
    @include add-horizontal-spacing;
    @include add-vertical-spacing($spacer-unit--large);

    @include from(pt) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
    }     

    &__inner {      
      margin: 0 auto;
      max-width: rem-calc(528);
      width: 100%; 
    }

    header > {
      .tag-line {
        color: palette(brand);
        margin-bottom: $spacer-smallest;
      }
      .heading{
        @include add-vertical-spacing($spacer-unit--small, margin, bottom);
      }
    }
  }

  &__inner {    
    @include from(pt) {
      display:         flex;
      flex-flow:       row;
      justify-content: center;
      align-items:     stretch;
      width: 100%; 
    }
  }

  &--left &__image,
  &--left &__images {
    order: 2;
  }

  .tag-line {    
    @include add-vertical-spacing($spacer-unit--small, margin, bottom);
  }

  .heading {    
    @include add-vertical-spacing($spacer-unit--small, margin, bottom);
  }

  .link-holder {    
    @include add-vertical-spacing($spacer-unit--mid, margin, top);

    @include from(lp) {    
      @include add-vertical-spacing($spacer-unit--small, margin, top);
    }
  } 

  &--gallery {

    .two-tile {

      &__inner{
        display: block;
        
        @include from(lt) {
          display:         flex;
          flex-flow:       row;
          justify-content: flex-start;
          align-items:     flex-start;
          width: 100%;         
        }    
      }

      &__images{
        display:         flex;
        flex-flow:       row wrap;
        justify-content: center;
        align-items:     center;                
        width: 100%;

        @include from(lt) {
          width: 54%;
        }
      }

      &__image{
        flex: 1 1 26%;
        width: 100%;

        min-height: 0;
        margin-left: $spacer-smallest;
        
        @include from(lt) {
          margin-top:  $spacer-smallest;  
        }

        @include between(lt, sd) {
          &:nth-child(2){
            display: none;
          }
        }

        &:nth-child(3n+1){
          margin-left: 0;          
        }

        &:nth-child(4){          
          flex: 1 1 100%; 

          @include from(lp) {
            margin-top: $spacer-smallest; 
          }

          @include to(pp) {
            img{
              width: 150%;
              max-width: none;  
            }
          }
        }
        
        @include to(pp) {        
          @include first(3) {
             display: none;
          }
        }
        
        img{
          display: block;
          width: 100%;
        }
      }
      
      &__content{

        @include from(lt) {
          padding-bottom: 0;        
        }

        @include to(pt) {
          width: 100%;
        }

        &__inner {
          margin: 0 auto;
          max-width: rem-calc(540);

          .tag-line {            
            @include add-vertical-spacing($spacer-unit--small, margin, bottom);
            color: $color--text;    
            @include from(lp) {
              @include add-vertical-spacing($spacer-unit--mid, margin, bottom);
            }
          }
        }
      }

      .link-holder {        
        @include add-vertical-spacing($spacer-unit--small, margin, top);

        @include from(lp) {
          @include add-vertical-spacing($spacer-unit--mid, margin, top);
        }
      }
    }
  }

  &--icon-list {    
    display: block;
    
    &.content-block.two-tile {
      @include add-horizontal-spacing();
    } 

    .two-tile {  
    
      &__inner {                      
        margin: 0 auto;
        max-width: rem-calc(1050);
        width: 100%;

        @include from(pt) {
          display:         flex;
          flex-flow:       row ;
          justify-content: space-between;
          align-items:     flex-start; 
        }
      }
  
      &__content {
        padding: 0; 
        @include add-vertical-spacing($spacer-unit--mid, margin, bottom);
        
        @include from(pt) {
          margin-right: $spacer-small;
          margin-bottom: 0;
        }   

        &__inner {          
          @include to(pp) {
            max-width: none;
          }
        }     
      }
    }

    .heading {
      .fancy-font {        
        @include from(pt) {
          margin-left: rem-calc(112);
        }
      }
    }

    .icon-list {
      margin:  0;
      padding: 0;

      @include at(lp) {
        max-width: rem-calc(528);
        margin: 0 auto;
      }

      &__item {
        list-style: none;
        padding: 0;
        margin:  0;
        cursor: pointer;

        &:first-child {
          .icon-list__link {
            border-top: 1px solid rgba(#fff, 0.2);
          }
        }
      }

      &__link {
        display: block;        
        padding: rem-calc(40 20 40 60);
        position: relative;
        border-bottom: 1px solid rgba(#fff, 0.2);
        
        color: $color--text;
        font-size:   rem-calc(18);
        font-weight: $font-weight--bold;
        letter-spacing: 0.1em;
        line-height: 1em;
        text-transform: uppercase;    

        svg {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: rem-calc(-20);
          height: rem-calc(40);
          width:  rem-calc(40);

          fill: palette(brand);

          &.icon--water-drop {
            height: rem-calc(32);
            width:  rem-calc(32);
            margin-top: rem-calc(-16);
            margin-left: rem-calc(4);
          }
        }
      }
    }
  }

  &--dual-content {
    &.two-tile.content-block {
      @include add-vertical-spacing();
    }

    .two-tile {
      
      &__inner {
        width: 100%;
        position: relative;
        margin: 0 auto;
        z-index: 10;

        @include from(pt) {
          display:         flex;
          flex-flow:       row;
          justify-content: center;
          align-items:     center;
          max-width: rem-calc(1727);          
        }
      }

      &__content {        
        padding-top: 0;
        padding-bottom: 0;        
        
        &:first-child {

          .two-tile__content__inner{
            @include add-vertical-spacing(128, margin, bottom);
            @include add-vertical-spacing($spacer-unit--mid, padding, bottom);             
            border-bottom: 1px solid rgba(#95989A, 0.36);
          }
        }

        @include to(pp) {
          text-align: left !important;
        }

        @include from(pt) {
          flex: 1 1 50%;

          &:first-child {
            border-right: 1px solid rgba(#95989A, 0.36);            
            
            .two-tile__content__inner {
              border-bottom: none;
              margin-bottom:  0;
              padding-bottom: 0;
            }
          }
        }
        
        &.restaurant-hatch {
          .two-tile__content__image {
            max-width: 59.8%;
          }
        }

        &.restaurant-1909 {
          .two-tile__content__image {
            display: block;
            max-width: 72.2%;
            width: 100%;;
          }
        }

        &.text-aligned {
          
          &--left {
            text-align: left;
          }
          
          &--center {
            text-align: center;
          }
          
          &--right {
            text-align: right;
          }
        }

        &__inner {
          max-width: rem-calc(496);
          margin: 0 auto;
        }

        
        &__image {        
          margin: 0 auto;
          margin-bottom: $spacer-small;
          img {
            display: block;
            width: 100%;


            .is-oldIE & {
              opacity: 1;
            }
          }
        } 


      }
    }
  }
}