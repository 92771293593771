//
// Blog
// --------------------------------------------------

body.blog,
body.archive {
  .main {
    background-color: palette(grey, lighter);
  }
}

.post-list {
  @include pie-clearfix;
  @include add-vertical-spacing($spacing-unit--horz);
  @include add-horizontal-spacing();
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  text-align: left;
}
