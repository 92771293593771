//
// Gallery
// --------------------------------------------------

.gallery {
  &.content-block {
    margin        : 0;
    padding-left  : 0;
    padding-right : 0;
    padding-bottom: 0;

    &.collapse-element{
      padding: 0;
      @include add-vertical-spacing(-72, margin, top);

      @include from(lp) {
        @include add-vertical-spacing(-96, margin, top);
      }

      @include from(ld) {
        @include add-vertical-spacing(-176, margin, top);
      }
    }

    &.gallery--padding-bottom {
      @include add-vertical-spacing($spacing-unit--vert, padding, bottom);
    }
  }

  &__inner {
    display        : flex;
    flex-flow      : row;
    align-items    : center;
    justify-content: center;
    //min-height: 8.4vw; //Lazy loading with textures

    @include responsive-vert-unit(min-height, 72);

    @include from(lp) {
      @include responsive-vert-unit(min-height, 96);
    }

    @include from(ld) {
      //@include add-vertical-spacing(-176, margin, top);
      @include responsive-vert-unit(min-height, 87);
    }

    position: relative;
    z-index : 10;
  }

  &__column {
    flex       : 1 1 auto;
    margin-left: rem-calc(10);

    @include from(lp) {
      margin-left: rem-calc(20);
    }
    &:first-child{
      margin-left: 0;
    }

    &--1 {
      width: 28.021875%;
      .gallery__item + .gallery__item {
        width       : 64%;
        margin-left : auto;
        margin-right: 0;
      }
    }

    &--2 {
      width: 24.14375%;
    }

    &--3 {
      width: 15.40677083%;
      @include to(pp) {
        display: none;
      }
    }


    &--4 {
      width: 14.26927083%;
      @include to(lp) {
        display: none;
      }
    }


    &--5 {
      width: 14.37239583%;
      @include to(pt) {
        display: none;
      }
    }
  }

  &__item {
    display   : block;
    width     : 100%;
    margin-top: rem-calc(10);

    @include from(lp) {
      margin-top: rem-calc(20);
    }

    img{
      display     : block;
      margin-left : auto;
      margin-right: 0;
      width       : 100%;

    }

    &:first-child{
      margin-top: 0;
    }
  }
}


// mfp

.mfp-fade.mfp-wrap.mfp-ready {
  &.next {
    .mfp-content {
      opacity   : 0;
      transition: all .4s $ease-out-cubic;
      transform : scale(1) translateX(-20%);
    }

    &.mfp-image-loading {
      .mfp-content {
        transform : scale(1) translateX(20%);
        transition: all 0s linear;
      }
    }
  }

  &.prev {
    .mfp-content {
      opacity   : 0;
      transition: all .4s $ease-out-cubic;
      transform : scale(1) translateX(20%);
    }

    &.mfp-image-loading {
      .mfp-content {
        transform : scale(1) translateX(-20%);
        transition: all 0s linear;
      }
    }
  }
}
