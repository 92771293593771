//
// Grid Mixins
// --------------------------------------------------
// @TODO: Add component spacing mixins?

$debug-grid: false !default;

@mixin grid-background {
  @if $debug-grid == true {
    @include background-grid;
  }
}

@mixin responsive-vert-unit($type, $unit) {
  
  //setup units
  $unit--vert--desk:  $unit;
  $unit--vert--tab:   $unit * $spacing-mod--vert--tab;  
  $unit--vert--mobile:$unit * $spacing-mod--vert--mobile;


  #{$type}: rem-calc($unit--vert--mobile);
  
  @include from(pt) {
    #{$type}: rem-calc($unit--vert--tab);
  }

  @include from(d) {
    #{$type}: rem-calc($unit--vert--desk);
  } 
}

@mixin responsive-horz-unit($type, $unit) {
  
  //setup units
  $unit--horz--desk:  $unit;
  $unit--horz--tab:   $unit * $spacing-mod--horz--tab;
  $unit--horz--mobile:$unit * $spacing-mod--horz--mobile;
  
  #{$type}: rem-calc($unit--horz--mobile);
  
  @include from(pt) {
    #{$type}: rem-calc($unit--horz--tab);
  }

  @include from(d) {
    #{$type}: rem-calc($unit--horz--desk);
  } 
}

@mixin _add-horizontal-spacing-right($horizontal-spacing: $spacing-unit--horz, $type: 'padding') {
  $horizontal-spacing--desk:   rem-calc($horizontal-spacing);
  $horizontal-spacing--tab:    rem-calc($horizontal-spacing * $spacing-mod--horz--tab);
  $horizontal-spacing--mobile: rem-calc($horizontal-spacing * $spacing-mod--horz--mobile);

  @if $horizontal-spacing == $spacing-unit--horz / 2 {
    $horizontal-spacing--mobile: rem-calc($spacing-unit--horz * $spacing-mod--horz--mobile);
  }

  #{$type}-right: $horizontal-spacing--mobile;

  @include from(lt) {
    #{$type}-right: $horizontal-spacing--tab;
  }

  @include from(d) {
    #{$type}-right: $horizontal-spacing--desk;
  }
}

@mixin _add-horizontal-spacing-left($horizontal-spacing: $spacing-unit--horz, $type: 'padding') {
  $horizontal-spacing--desk:   rem-calc($horizontal-spacing);
  $horizontal-spacing--tab:    rem-calc($horizontal-spacing * $spacing-mod--horz--tab);
  $horizontal-spacing--mobile: rem-calc($horizontal-spacing * $spacing-mod--horz--mobile);

  @if $horizontal-spacing == $spacing-unit--horz / 2 {
    $horizontal-spacing--mobile: rem-calc($spacing-unit--horz * $spacing-mod--horz--mobile);
  }

  #{$type}-left: $horizontal-spacing--mobile;

  @include from(lt) {
    #{$type}-left: $horizontal-spacing--tab;
  }

  @include from(d) {
    #{$type}-left: $horizontal-spacing--desk;
  }
}

@mixin add-horizontal-spacing($horizontal-spacing: $spacing-unit--horz, $type: 'padding', $direction: 'both') {
  @if $direction == 'right' or $direction == 'both' {
    @include _add-horizontal-spacing-right($horizontal-spacing, $type);
  }
  @if $direction == 'left' or $direction == 'both' {
    @include _add-horizontal-spacing-left($horizontal-spacing, $type);
  }
}

@mixin _add-vertical-spacing-top($vertical-spacing, $type) {
  //setup padding variables if one was provided

  //setup units
  $spacing-unit--vert--desk:   $vertical-spacing;
  $spacing-unit--vert--tab:    $vertical-spacing * $spacing-mod--vert--tab;
  $spacing-unit--vert--mobile: $vertical-spacing * $spacing-mod--vert--mobile;


  #{$type}-top: rem-calc($spacing-unit--vert--mobile);

  @include from(pt) {
    #{$type}-top: rem-calc($spacing-unit--vert--tab);
  }

  @include from(d) {
    #{$type}-top: rem-calc($spacing-unit--vert--desk);
  }
}

@mixin _add-vertical-spacing-bottom($vertical-spacing, $type) {
  //setup padding variables if one was provided

  //setup units
  $spacing-unit--vert--desk:   $vertical-spacing;
  $spacing-unit--vert--tab:    $vertical-spacing * $spacing-mod--vert--tab;
  $spacing-unit--vert--mobile: $vertical-spacing * $spacing-mod--vert--mobile;


  #{$type}-bottom: rem-calc($spacing-unit--vert--mobile);

  @include from(pt) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--tab);
  }

  @include from(d) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--desk);
  }
}

@mixin add-vertical-spacing($vertical-spacing: $spacing-unit--vert, $type: 'padding', $direction: 'both') {
  @if $direction == 'top' or $direction == 'both' {
    @include _add-vertical-spacing-top($vertical-spacing, $type);
  }
  @if $direction == 'bottom' or $direction == 'both' {
    @include _add-vertical-spacing-bottom($vertical-spacing, $type);
  }
}

@mixin remove-vertical-spacing-top($vertical-spacing: $spacing-unit--vert) {
  //setup padding variables if one was provided
  $neg-vertical-spacing: $vertical-spacing * -1;

  @include _add-vertical-spacing-top($neg-vertical-spacing, 'margin');
  @include _add-vertical-spacing-top($vertical-spacing/2, 'padding');
}

@mixin add-spacing($spacing: $spacing-unit--horz, $type: 'padding') {
  $count: length($spacing);

  @if $count == 1 {
    @include add-vertical-spacing($spacing, $type);
    @include add-horizontal-spacing($spacing, $type);
  }
  @else if $count == 2 {
    @include add-vertical-spacing(nth($spacing, 1), $type);
    @include add-horizontal-spacing(nth($spacing, 2), $type);
  }
  @else if $count == 3 {
    @include _add-vertical-spacing-top(nth($spacing, 1), $type);
    @include add-horizontal-spacing(nth($spacing, 2), $type);
    @include _add-vertical-spacing-bottom(nth($spacing, 3), $type);
  }
  @else if $count == 4 {
    @include _add-vertical-spacing-top(nth($spacing, 1), $type);
    @include _add-horizontal-spacing-right(nth($spacing, 2), $type);
    @include _add-vertical-spacing-bottom(nth($spacing, 3), $type);
    @include _add-horizontal-spacing-left(nth($spacing, 4), $type);
  }
}

@mixin add-component-spacing($vertical-spacing: $spacing-unit--component, $type: 'padding') {
  $spacing-unit--component--desk:   $vertical-spacing;
  $spacing-unit--component--tab:    $vertical-spacing * $spacing-mod--component--tab;
  $spacing-unit--component--mobile: $vertical-spacing * $spacing-mod--component--mobile;


  #{$type}: rem-calc($spacing-unit--component--mobile);

  @include from(pt) {
    #{$type}: rem-calc($spacing-unit--component--tab);
  }

  @include from(d) {
    #{$type}: rem-calc($spacing-unit--component--desk);
  }
}

.cf {
  @include clearfix;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

%l-constrained {
  min-width:  rem-calc(320);
  max-width: rem-calc($max-content-width--wide);
}
