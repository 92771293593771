.texture {
  position: absolute;
  
  &--overlay {    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    //background: #000;
    
    .texture__image {
      &,
      &:before, 
      &:after {        
        background-image: url('../images/textures/texture.png');                
        background-repeat: repeat;
        background-position: center center;
        background-size: 1920px auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0.5;
      }
      
      &.texture {

        &--opacity-15 {
          opacity: 0.1;
        }

        &--behavior-repeat-tighten {
          &,
          &:before,
          &:after {
            background-size: percentage(1/2) auto;
          }
          &:before,
          &:after {
            content: '';            
            height: 50%;            
          }
          &:before {
            transform: translateY(-100%) scaleX(-1) scaleY(-1);
          }
          &:before {
            transform: translateY(100%) scaleX(-1) scaleY(-1);
          }
        }
      }      
    }

    .texture--ripped.texture--bottom + & {
      z-index: 5;
      bottom: rem-calc(-10);

      @include from(lt) {
        bottom: rem-calc(-20);
      }
    }
    
    .texture--ripped.texture--top + & {
      z-index: 5;
      top: rem-calc(-10);

      @include from(lt) {
        top: rem-calc(-20);
      }      
    }
    
    .collapse-element & {      
      @include responsive-vert-unit(top, 176);
    }  
  }

  &--ripped {    
    left:0;
    width: 100%;    
    z-index: 1;
    
    &.texture--top {
      top:0;  
      margin-top: rem-calc(-10);
      
      @include from(lt) {
        margin-top: rem-calc(-20);
      }
    }

    &.texture--bottom {
      bottom:0;  
      margin-bottom: rem-calc(-10);      

      @include from(lt) {
        margin-bottom: rem-calc(-20);
      }
    }

    .texture__image {
      display: block;
      margin: 0;
      max-width: none;
      max-height: none;      
      height: auto;
      width: 120%;
      margin-left: -10%;
      
      @include from(lp) {
        width: 110%;
        margin-left: -5%;
      }
    }
  }
} 

.content-block {
  &.background-color--light.padding-collapse.has-texture + .background-color--light.padding-collapse.has-texture,
  &.background-color--dark.padding-collapse.has-texture + .background-color--dark.padding-collapse.has-texture {

    .texture--overlay {
      &:before {
        content: '';
        background-image: linear-gradient(to top, rgba(#fff, 0), rgba(#fff,1), rgba(#fff,0));        
        opacity: 0.7; 
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;      
        z-index: 10;      
        
        @include responsive-vert-unit(height, 160);
        @include responsive-vert-unit(margin-top, -80);
      }
    }
    
    .texture__image {      
      transform: scaleX(-1);

      &,
      &:before {
        @include responsive-vert-unit(top, $spacing-unit--vert);  
      }
    }
  }
}