//
// Loader Overlay
// --------------------------------------------------

.overlay-show {
  @include overlay(0, palette(black), fixed, none);
  //transition: opacity .4s $transitions--base;
  z-index: 1000;

  .overlay-visible &,
  &.show {
    pointer-events: all;
    //opacity: .6;
  }
}


//
// Loader
// --------------------------------------------------

.loader {
  $l: &;

  transform: translateX(101%);
  background-color: transparent;

  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; // has to be scroll, not auto
  position: fixed;
  top:   0;
  right: 0;

  height: 100%;
  width:  100%;
  z-index: 1300;

  &.show {
    //transform: translate3d(0, 0, 0) scale(1);
  }

  &__content {
    padding: rem-calc(40 20);

    @include from(lt) {
      padding: rem-calc(60 50 40 50);
    }
  }

  &__close {
    color: $color--text;
    position: absolute;
    top:   0;
    right: 0;
    font-size: rem-calc(14);
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem-calc(30 30 0 0);
    height: rem-calc(70);
    width:  rem-calc(70);
    z-index: 10;

    span {
      margin-right: rem-calc(10);
    }

    svg {
      fill: $color--text;
      height: rem-calc(28);
      width:  rem-calc(28);
    }
  }

  .no-csstransforms & {
    right: -200%;

    &.show {
      right: 0%;
    }
  }

  .img-wrap {
    margin-right:   auto;
    margin-left:    auto;
    padding-bottom: 0%;
    height: rem-calc(300);
    width: rem-calc(300);
  }

  .header {
    margin-top:    rem-calc(30);
    margin-bottom: rem-calc(40);

    .title,
    .sub-title {
      margin-bottom: rem-calc(5);
    }
  }

  &--popup {
    background-color: palette(grey, darker);
    color: palette(white);
    top:    50%;
    left:   50%;
    right:  auto;
    bottom: auto;
    transform: translate(-50%, 100vh);
    text-align: center;
    opacity: 0;
    transition: transform .4s $ease-out-cubic, opacity .6s $ease-out-cubic;
    height: 100%;
    max-width: none;
    width:  100%;

    @include from(pt) {
      height: auto;
      width: rem-calc($max-content-width--wide);
      max-width: 80%;
    }

    &.show {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    #{$l}__close {
      box-sizing: content-box;
      line-height: 0;
      padding: rem-calc(20);
      height: rem-calc(40);
      width:  rem-calc(40);

      .hamburger {
        display: block;
        height: 100%;
        width: 100%;

        &-inner {
          &::before, &::after {
            background-color: palette(white);
            top: 0;
            transition: background-color .15s $transitions--base;
          }

          &::before {
            transform: translate3d(0, 10px, 0) rotate(45deg);
          }

          &::after {
            transform: translate3d(0, 10px, 0) rotate(-45deg);
          }
        }

        &:hover {
          .hamburger-inner {
            &::before, &::after {
              background-color: palette(brand);
            }
          }
        }
      }
    }

    #{$l}__content {
      @include add-vertical-spacing;
      padding-left:  rem-calc(40);
      padding-right: rem-calc(40);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      @include from(lp) {
        padding-left:  rem-calc(60);
        padding-right: rem-calc(60);
      }

      @include from(lt) {
        padding-left:  rem-calc($spacing-unit--horz);
        padding-right: rem-calc($spacing-unit--horz);
      }
    }

    #{$l}__heading {
      color: palette(white);

      @include from(pt) {
        font-size: rem-calc($font-size--h1);
        line-height: 1;
      }
    }

    #{$l}__ctas {
      margin-top: rem-calc(40);
      width: 100%;

      @include from(pt) {
        margin-top: rem-calc(60);
      }
    }
  }

  &--flyout,
  &--book-now {

    .loader {
      &__close {
        svg {
          fill: palette(white);

          @include from(d) {
            fill: $color--text;
          }
        }
      }
      &__content {
        padding: 0;
        height: 100%;
      }
    }

    &.has-no-images {
      .loader__close {
        svg{
          fill: $color--text;
        }
      }
    }
  }

}

.visible-off-canvas {
  display: none;

  .loader & {
    display: block;
  }
}


//
// Flyout
// --------------------------------------------------

.flyout {

  &__inner {
    display:block;
    //flex-flow:       column;
    //justify-content: center;
    //align-items:     stretch;
    min-height:100%;

    @include from(d) {
      display:         flex;
      flex-flow: row;
      justify-content: center;
      align-items:     stretch;
      height:100%;
    }
  };

  &__images {
    background: #333;
    width: 100%;
    height: 50vh;
    min-width: 0;//Weird Fix for firefox & swiper
    min-height: 0;//Weird Fix for firefox & swiper

    @include from(lp) {
      //height: 35vw;
    }

    @include from(d) {
      flex: 0 1 55%;
      width: 55%;
      height: auto;
    }

    @include from(vld) {
      flex: 1 1 60%;
      width: 60%;

    }
  }

  &__image {
    height:100%;
    width: 100%;
  }

  &__slider {

    &,
    .swiper-container,
    .swiper-wrapper,
    .swiper-slide {
      height: 100%;
      width: 100%;
    }

    .swiper-navigation {
      position: absolute;
      bottom: 0;
      right:  0;
      z-index: 10;
      display:         flex;
      flex-flow:       row;
      justify-content: center;
      align-items:     center;
      padding: rem-calc(25);

      .circle-arrow {
        height: rem-calc(40);
        width: rem-calc(40);

        .border,
        .arrow {
          fill: palette(white);
        }

        .background {
          fill: transparent;
        }
      }

      .swiper-prev {
        margin-right: rem-calc(10);

        .circle-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__content {
    display: block;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    @include add-horizontal-spacing($spacer-unit--mid);
    @include add-vertical-spacing($spacer-unit * 10);
    flex: 1 1 100%;
    width: 100%;
    min-height: 100%;

    background: palette(white);
    &__inner{
      max-width: rem-calc($spacer-unit * 73);
      margin: 0 auto;
    }

    @include from(d) {
      flex: 0 1 45%;
      width: 45%;
      overflow: auto;
    }

    @include from(ld) {
      @include add-horizontal-spacing($spacer-unit--large);
      @include add-vertical-spacing($spacer-unit * 14);
    }

    @include from(vld) {
      flex: 0 1 40%;
      width: 40%;

      &__inner{
        max-width: none;
      }
    }

    &__inner {
      position: relative;
      z-index: 10;
    }

    .fancy-font {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1em;
      transform: rotate(0deg);

      color: $color--text;
      font-size: rem-calc(80);
      text-transform: none;

      @include from(lp) {
        font-size: rem-calc(99);
      }
    }

  }

  &--loader{

  }

  &--restaurant-menu {

  }

  &--adventure {

  }

  &--book-now {
    .flyout {

      &__heading {
        @include add-vertical-spacing($spacer-unit * 6, margin, bottom);
      }

      &__content {
        @include add-vertical-spacing($spacer-unit * 9, padding, top);
        padding-bottom: 0;

        &__inner {
          min-height: 100%;
          display:         flex;
          flex-flow:       column;
          justify-content: flex-start;
          align-items:     flex-start;
          padding-bottom: $spacer-small;
        }
      }
    }
  }
}

//
// Restaurant Menus
// --------------------------------------------------
.loader .restaurant-menu {

  &__heading {

    @include add-vertical-spacing($spacer-unit--small, margin, top);
    margin-bottom: $spacer-small;

    color: palette(blue, dark);
    letter-spacing: 0;
    font-size: rem-calc(50);
    line-height: 1em;
    text-transform: lowercase;

    @include from(pt) {
      @include add-vertical-spacing($spacer-unit--mid, margin, top);
    }
  }

  &__item {
    display:         flex;
    flex-flow:       row;
    justify-content: space-between;
    align-items:     flex-start;
    width: 100%;

    @include add-vertical-spacing($spacer-unit--small, margin, bottom);

    &:last-child{
      margin-bottom: 0;
    }

    &__content {
      @include add-horizontal-spacing($spacer-unit--mid, padding, right);
    }

    &__title {
      margin-bottom: rem-calc(8);

      font-family: $font-family--body;
      font-size: rem-calc(22);
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--small;
      text-transform: uppercase;
    }

    &__description {
      &,
      p {
        line-height: 1.5em;
      }
    }

    &__price {
      color: palette(brand);
      font-weight: $font-weight--bold;
      line-height: 1em;
    }

    &--no-description {
      @include add-vertical-spacing($spacer-unit--smallest, margin, bottom);

      .restaurant-menu__item__title {
        margin-bottom: 0;
      }
    }
  }
}

//
// Book now form
// --------------------------------------------------
.loader .flyout--book-now {
  input[type='tel'],
  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  textarea,
  select {
    appearance: none;
    // @TODO: Replace colors with variables?
    background: transparent;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    box-sizing: border-box;
    color: $color--text;
    display: block;
    padding: 0 rem-calc(30);
    font-family: $font-family--body;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25em;
    height: rem-calc(60);
    vertical-align: middle;
    resize: none;
    outline: 0;
    width: 100%;
    position: relative;
    z-index: 10;

    @include from(pt) {
      font-size: rem-calc(20);
    }

    &::placeholder {
      color: #868686;
    }
  }

  .sel-wrapper {
    position: relative;
    background: palette(white);
  }
  .booking-widget {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     flex-start;

    > .booking-widget__label,
    &__element {
      display: block;
      flex: 1 1 100%;
    }

    &__element {
      margin-bottom: $spacer-smallest;

      &--small {
        flex: 1 1 25%;
        & + .booking-widget__element--small{
          margin-left: rem-calc(36);
        }
      }

      &--button{
          margin-top: rem-calc($spacer-unit * 2);
      }

      &--text-link{
        margin-top: auto;
        margin-bottom: 0;
        ///padding-top: $spacer-mid;
      }
    }

    &__label {
      margin-bottom: rem-calc(10);

      font-size: rem-calc(18);
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--small;
      line-height: 1em;
      text-transform: uppercase;

      &--small {
        font-size: rem-calc(18);
        font-weight: $font-weight--regular;
        letter-spacing: 0px;
        text-transform: none;
      }
    }

    svg {
      position: absolute;
      display: block;
      top:50%;
      right: rem-calc(20);
      margin-top: rem-calc(-10);
      height: rem-calc(20);
      width:  rem-calc(20);
      z-index: 5;

      fill: palette(brand);
    }

    &__text-link {
      margin-top: auto;
      margin-bottom: 0;
      a{
        color: $color--text;
        font-size: rem-calc(20);

        &:hover{
          color: $color--hover;
        }
      }
    }
  }
}
