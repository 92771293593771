//
// Navigation
// --------------------------------------------------
$nav-link-color: $color--text;
$nav-link-spacing: rem-calc(10);
$nav-font-size: rem-calc(16);
$nav-font-weight: $font-weight--bold;

.header-down {
  .nav--desktop {
    .nav__main {
      #menu-primary-navigation > .menu-item {
        .sub-menu-wrapper,
        > a img {
          top: 100%;
        }
      }
    }
  }
}

.hero-nav-color--light {
  header:not(.header-down) {
    .nav__phone--desktop,
    #menu-primary-navigation > .menu-item > a {
      color: palette(white);
      transition: all 0.6s $ease-out-cubic;

      &:hover {
        color: lighten(palette(grey, light), 8%);
      }
    }

    #menu-primary-navigation > .menu-item {
      &[class*='packages'] > a {
        &:hover {
          color: palette(blue, base);
        }
      }
    }
  }
}

.nav__phone {
  display: none;
  align-items: center;
  letter-spacing: 0.04em;
  color: palette(black, base);
  font-size: rem-calc(20);
  font-weight: bold;
  transition: color 0.6s $ease-out-cubic;

  &:hover {
    color: lighten(palette(blue, base), 10%);
  }

  &.nav__phone--mobile {
    display: inline-block;
    height: auto;
    color: #fff;
    margin-bottom: rem-calc(50);
    font-size: rem-calc(30);

    &:hover {
      color: palette(grey, light);
    }

    @include from(lt) {
      display: none;
    }
  }

  &.nav__phone--desktop {
    display: none;

    @include from(lt) {
      display: inline;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 120%;
    }
  }

  .header-float & {
    transform: translateY(-100%);
    transition: transform 0s linear;

    &.header-up {
      transform: translateY(-100%);
    }

    &.header-down {
      @include from(lt) {
        transform: translateY(0%);
        display: inline;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 120%;
      }
    }
  }
}

.nav {
  &--right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .pace-done & {
      visibility: visible;
      opacity: 1;
    }
  }

  &--desktop {
    width: 100%;
    display: none;
    justify-self: flex-end;

    .pace-done & {
      opacity: 1;
      visibility: visible;
    }

    @include from(lt) {
      display: block;
      padding-right: 12rem;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 82vw;
      max-width: 1240px;
    }


    @include from(sd) {
      padding-right: 12.5rem;
    }


    @include from(xld) {
      max-width: 1440px;
    }


    backface-visibility: hidden;

    .nav {
      &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        min-height: 100%;
        width: 100%;
      }

      &__main {
        width: 100%;

        &__inner {
          max-width: rem-calc($max-content-width--wide * 1.15);
          margin-right: 0;
          margin-left: auto;
          width: 100%;
        }

        #menu-primary-navigation > .menu-item {
          &[class*='packages'] > a {
            background: palette(brand, base);
            border-left: 1.1rem solid palette(brand, base);
            border-right: 1.1rem solid palette(brand, base);
            border-top: 0.4rem solid palette(brand, base);
            border-radius: $button-border-radius;

            &:hover {
              color: palette(white);
            }
          }

          > a {
            display: block;
            width: 100%;
            border-bottom: 0.4rem solid transparent;
            font-size: rem-calc(15);
            text-transform: uppercase;
            letter-spacing: 0.08em;
            color: palette(blue, dark);
            font-family: $font-family--body;
            font-weight: 700;

            &#{&},
            span {
              font-size: rem-calc(20);
            }

            &:hover {
              color: lighten(palette(blue, base), 1%);
            }

            .icon {
              display: none;
            }

            .custom-object-fit {
              background-size: cover;
              background-position: center center;
            }

            img,
            .custom-object-fit {
              position: absolute;
              top: 100%;
              right: 0;
              width: 40%;
              object-fit: cover;
              height: 100%;
              transition-property: opacity, visibility;
              transition-duration: .4s;
              transition-timing-function: $ease-out-cubic;
              visibility: hidden;
              opacity: 0;
              display: none;

              &.vis-show {
                visibility: visible;
                opacity: 1;
              }

              .pace-done & {
                display: block;
              }
            }
          }

          &.hover-active {
            // submenu open
            > a {
              border-bottom: 0.4rem solid palette(brand, base);

              > img,
              .custom-object-fit {
                visibility: visible;
                opacity: 1;
              }
            }

            .sub-menu-wrapper {
              visibility: visible;
              opacity: 1;
            }

            .sub-menu {
              .pace-done & {
                visibility: visible;
                opacity: 1;
              }
            }
          } // submenu-wrapper
          .sub-menu-wrapper {
            .pace-done & {
              display: flex;
            }

            display: none;
            justify-content: space-between;
            flex-direction: column;
            padding: 2.5rem;

            &:before {
              content: '';
              background-image: url("../images/textures/texture.png");
              background-repeat: repeat;
              background-position: center center;
              background-size: 100%;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              opacity: 0.3;
            }

            @include from(d) {
              padding: 3.2rem;
            }


            width: 60%;
            min-height: 350px;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: palette(blue, dark);
            transition-property: opacity, visibility;
            transition-duration: .4s;
            transition-timing-function: $ease-out-cubic;
            visibility: hidden;
            opacity: 0;

            .menu-message {
              color: #fff;
              font-weight: 700;
              line-height: 1.4;
            }

            .cta-message {
              color: palette(brand, base);
              display: inline-flex;
              align-items: center;
              transition: color 0.4s $ease-out-cubic;

              &:hover {
                color: darken(palette(brand, base), 10%);

                .cta-arrow {
                  transform: translateX(5px);
                }
              }
            }

            .cta-arrow {
              transition: transform 0.4s $ease-out-cubic;
              transform: translateX(0);
              margin-left: 10px;
              background-position: center;
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwb2x5Z29uIHBvaW50cz0iMTUsMi43NSAxMi45MTQsNC44MzYgMzMuMDc4LDI1IDEyLjkxNCw0NS4xNjQgMTUsNDcuMjUgMzcuMjUsMjUgIi8+PC9zdmc+);
              background-repeat: no-repeat;
              background-size: 60%;
              background-color: palette(brand, base);
              width: 30px;
              height: 30px;
              display: inline-block;
              border-radius: 50%;
            }
          }

          .sub-menu,
          .menu-message {
            position: relative;
            z-index: 20;
            height: 100%;
          }

          .sub-menu {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            padding: 0;
            max-width: 600px;
            height: 200px;

            @include from(d) {
              height: 240px;
            }


            @include from(ld) {
              height: 260px;
            }


            a {
              font-family: $font-family--body;
              font-size: rem-calc(26);
              transform: translateX(3.5rem);
              width: 100%;
              line-height: 1.2;
              display: flex;

              img {
                width: 12%;
                margin-right: 1.2em;
                height: 100%;
              }
              img,span{
                transform: translateX(-3.5rem);
              }
            }
          }
        }
      }

      &__list {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-left: 0;

        > .nav__list-item {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &__list-item {
        list-style: none;

        &:not(:last-child) {
          @include add-horizontal-spacing(18, margin, right);

          @include from(sd) {
            @include add-horizontal-spacing(27, margin, right);
          }


          @include from(d) {
            @include add-horizontal-spacing(29, margin, right);
          }
        }
      }

      &__list-link {
        @extend %dft;

        color: palette(white);
        display: inline-flex;
        flex-flow: row;
        justify-content: flex-start;
        font-size: rem-calc(24);

        .icon {
          display: block;
          fill: palette(brand);
          height: rem-calc(15);
          width: rem-calc(15);
          margin-right: rem-calc(5);
        }

        &:hover {
          color: palette(brand);

          .icon {
            animation: wobbleHorz 1000ms linear infinite;
          }
        }
      }

      &__main .sub-menu-wrapper {
        padding-left: 0;
        margin-left: 0;

        .nav {
          &__list-item {
            margin-left: 0;
            height: 50px;
            width: 45%;

            @include from(d) {
              height: 60px;
            }


            @include from(ld) {
              height: 65px;
            }


            @include from(xld) {
              width: 48%;
            }
          }
        }
      }
    }
  }
}
