//
// Button Mixins
// --------------------------------------------------
//
$button-background-default: transparent;
$button-color-default:      palette(blue, dark);

$button-font:           $font-family--body;
$button-font-size:      rem-calc(18);
$button-font-weight:    $font-weight--bold;
$button-letter-spacing: $letter-spacing--small;
$button-border-radius:  rem-calc(80);
$button-padding:        rem-calc(16 48);



@mixin button($background: $button-background-default, $color: $button-color-default) {
  color: $color;
  background: $background;
  font-family: $button-font;
  font-size: 16px;
  font-weight: $button-font-weight;
  letter-spacing: $button-letter-spacing;
  line-height: 1em;
  text-align: center;
  padding: 17.5px 50px;
  position: relative;
  border: 2px solid $button-color-default;
  border-radius: $button-border-radius;
  width: 100%;
  text-transform: uppercase;

  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  transition-property: background-color, color, border;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  appearance: none;
  
  @include from(pt) {
    width: auto;
    padding: $button-padding;
    font-size: $button-font-size;
  }
  
  &,
  &:focus{
    outline: none;
  }

  &:hover {
    background-color: palette(blue, dark);
    color: palette(white);
  }

  &.white {
    background-color: palette(white);
    color: palette(blue, dark);
    border-color: palette(white);

    &:hover {
      background-color: transparent;
      color: palette(white);
    }

    &.transparent {
      background-color: transparent;
      color: palette(white);
      border-color: palette(white);

      &:hover {
        background-color: palette(white);
        color: palette(blue, dark);
      }
    }
  }

  &.invert {
    background-color: $background;
    border-color: $color;

    &:hover {
      background-color: $color;
      color: $background;
    }
  }
  
  &.brand {

    background-color: palette(brand);
    border-color: palette(brand);
    color: palette(white);

    &:hover {
      background-color: palette(brand, accent);
      color: palette(white);
      border-color: palette(brand, accent);
    }

    &.transparent {
      background-color: transparent;
      border-color: palette(brand);
      color: palette(brand);

      &:hover {
        background-color: palette(brand);
        color: palette(white);
        border-color: palette(brand);
      }
    }
  }

  &.transparent {
    background-color: transparent;
    border-color: $background;

    &:hover {
      background-color: $color;
      color: $background;
      border-color: $color;
    }
  }

  svg {
    fill: #fff;
    left: rem-calc(10);
    margin-left: rem-calc(5);
    height: rem-calc(20);
    width:  rem-calc(20);

    @include from(d) {
      margin-left: rem-calc(10);
    }
  }
}
