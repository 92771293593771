.adventure-single {

  .swiper {
    @include add-vertical-spacing($spacing-unit--vert/2, margin, top);
  }

  .swiper-navigation {
  position: absolute;
  bottom: 0;
  right:  0;
  z-index: 10;
  display:         flex;
  flex-flow:       row;
  justify-content: center;
  align-items:     center;
  padding: rem-calc(25);

  .circle-arrow {
    height: rem-calc(40);
    width: rem-calc(40);

    .border,
    .arrow {
      fill: palette(white);
    }

    .background {
      fill: transparent;
    }
  }

  .swiper-prev {
    margin-right: rem-calc(10);

    .circle-arrow {
      transform: rotate(180deg);
    }
  }
  }
}
//
// Adventure Menus
// --------------------------------------------------

.adventure {

  &__duration {
    display: block;
    margin-bottom: $spacer-smallest;

    color: palette(brand);
    font-family: $font-family--body;
    line-height: 1em;
  }

  &__heading {
    line-height: 0.7em;
    display: block;
    margin-top: -0.3em;
    margin-bottom: $spacer-small;
  }

  &__summary {
    margin-bottom: $spacer-mid;
  }

  &__details {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: space-between;
    align-items:     stretch;

    &.num-of-colums-1 {
      .adventure__column{
        flex: 0 1 auto;
        width: auto;
      }
    }
  }

  &__column {
    flex: 0 1 50%;
    padding-right: rem-calc($spacer-unit * 4);
    width: 50%;
    margin-top: $spacer-small;

    @include first(2){
      margin-top: 0;
    }
    @include from(lp) {
      padding-right: $spacer-small;
    }

    &:nth-child(even){
      border-left:  1px solid rgba(#244C5A, 0.21);
      padding-left: rem-calc($spacer-unit * 4);
      padding-right: 0;

      @include from(lp) {
        padding-left: $spacer-small;
      }
    }



    &__heading {
      display: block;
      margin-bottom: $spacer-smallest;

      color: palette(brand);
      font-family: $font-family--body;
      line-height: 1.25em;
    }
  }

  &__list {
    margin:  0;
    padding: 0;
  }

  &__list-item {
    display:         flex;
    flex-flow:       row;
    justify-content: space-between;
    align-items:     center;
    margin:  0;
    padding: rem-calc(8 0);
    list-style: none;
    line-height: 1.125em;


    &__label{
      font-weight: 700;
      margin-right: rem-calc(20);

    }
  }

  &__disclaimer {
    display: block;
    margin-top: $spacer-smallest;
  }

  &__links{
    margin-top: $spacer-mid;
    margin-bottom: -1rem;
    text-align: center;

    .button,
    .btn {
      &,
      & + .btn,
      & + .button {
        margin: 0 0.5rem 1rem;
      }
    }
  }
}
