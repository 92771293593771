//
// Contact page
// --------------------------------------------------

.page-template-template-contact {
  .hero {
    
  }
  .contact {
    &__content {
      position: relative;
      @include add-vertical-spacing();
      @include add-horizontal-spacing();
      
      &__inner {
        display: block;
        position: relative;
        z-index: 10;
        max-width: rem-calc(1440);
        margin: 0 auto;
        width: 100%;

        @include from(pt) {
          display:         flex;
          flex-flow:       row;
          justify-content: flex-start;
          align-items:     stretch;
        }
      }

      &__column {        
        @include add-horizontal-spacing(80, margin, right);
        flex: 0 0 auto;

        &:first-child {
          flex: 1 1 auto;  
          margin-right: 0;
          @include add-vertical-spacing($spacer-unit--large, margin, bottom);
          order: 2;

          @include from(pt) {
            margin-bottom: 0;
          }
        }
      }
      &__heading {
        margin-top: 0;
        margin-bottom: rem-calc($spacer-unit * 4);

        font-family: $font-family--body;
        font-size: rem-calc(20);
        font-weight: 700;
        letter-spacing: $letter-spacing--small;
        text-transform: uppercase;
      }
      
      &__address,
      &__list {
        padding: 0;
        margin: 0;
      }

      &__list {
        margin-top: rem-calc($spacer-unit * 4);
      }

      &__address-item,
      &__list-item {
        list-style: none;        
        font-size: rem-calc(24);
        line-height: 1.5em;
      }

      &__list-link {
        @extend %dft;
        color: $color--text;
        font-size: rem-calc(24);

        &:hover{
          color: palette(brand);
        }
      }

      &__label{
        font-weight: $font-weight--bold;
      }
    
      &__form {
        .gform_title {
          margin-bottom: rem-calc($spacer-unit * 4);
        }
      }
    
      .social-media{
        margin-top: rem-calc($spacer-unit * 4);
        
        svg {
          fill: palette(brand);
        }
      }
    }
  }

  .map-handler {      
    height: 300px;
    
    @include from(pt) {
      height: 400px;
    }

    @include from(sd) {
      height: 520px;
    }

    @include from(vld) {
      height: 700px;
    }

    img {
      max-width: none;
    }
  }
}

.contact-form {
  @include add-horizontal-spacing;
  @include add-vertical-spacing;
  background-color: palette(grey, lighter);

  &__inner {
    margin-left:  auto;
    margin-right: auto;
    max-width: rem-calc($max-content-width--wide)
  }

  .heading {
    margin-bottom: rem-calc(40);
    text-align: center;
  }

  input[type='tel'],
  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  textarea,
  select {
    background-color: palette(white);
  }
}

.contact-info {
  svg {
    fill: palette(brand);
    margin-right: rem-calc(10);
    height: rem-calc(20);
    width:  rem-calc(20);
  }

  &__phone-numbers {
    margin-bottom: rem-calc(20);
  }

  &__hours {
    list-style: none;
    padding: 0;

    li {
      padding: 0;
    }
  }

  .contact__label {
    font-weight: $font-weight--bold;
  }

  &--mobile {
    background-color: palette(brand);
    display: flex;
    position: fixed;
    bottom: 0;
    left:   0;
    width: 100%;
    z-index: 100;

    .header-float & {
      border-bottom: 1px solid palette(grey, light);
      position: fixed;
      transform: translateY(100%);
      transition: transform .4s $ease-out-cubic;

      &.header-up,
      &.header-down {
        transition: transform .4s $ease-out-cubic;
      }

      &.header-up {
        transform: translateY(100%);
      }

      &.header-down {
        transform: translateY(0%);
      }
    }

    @include from(lp) {
      position: relative;
      z-index: 1;
    }

    @include from(pt) {
      display: none;
    }
  }

  &__cta {
    color: palette(white);
    border-bottom: 1px solid palette(white, brand);
    font-size: rem-calc(13);
    letter-spacing: $letter-spacing--medium;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;
    width: percentage(1/3);

    &:not(:last-child) {
      border-right: 1px solid palette(white, brand);
    }

    &__content,
    &::before {
      padding: rem-calc(15);
      transition: transform .9s $ease-in-out-back .1s;

      @include from(lp) {
        padding: rem-calc(20);
      }

      @include from(pt) {
        padding: rem-calc(30);
      }
    }

    &__content {
      display: block;
      background: palette(brand);
      padding-bottom: rem-calc(12);
      height: 100%;

      // @TODO: Update big / small classnames
      .big {
        display: none;
      }

      .small {
        display: inline-block;
      }

      @include from(lt) {
        padding: rem-calc(20 10 30);

        .big {
          display: inline-block;
        }

        .small {
          display: none;
        }
      }
    }

    &::before {
      content: attr(data-hover);
      background: palette(brand, two);
      color: palette(white);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-25%);
      position: absolute;
      top:  0;
      left: 0;
      height: 100%;
      width:  100%;
      z-index: -1;
    }

    .icon {
      display: block;
      line-height: 1;
      fill: white;
      margin: 0 auto rem-calc(10);
      height: rem-calc(30);
      width:  rem-calc(30);

      @include from(lp) {
        height: rem-calc(30);
        width:  rem-calc(30);
      }

      @include from(pt) {
        height: rem-calc(40);
        width:  rem-calc(40);
      }
    }

    &:hover,
    &:focus {
      color: #FFF;

      @include from(sd) {
        .contact-info__cta__content {
          transform: translateX(100%);
        }

        &::before {
          transform: translateX(0%);
        }
      }
    }
  }
}
