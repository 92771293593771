.feature-events {
  
  &.content-block {    
    @include add-horizontal-spacing(86);
  }

  &__heading {
    @include add-vertical-spacing($spacing-unit--vert, margin, bottom);
    position: relative;
    z-index: 10;

    color: palette(brand, accent);
    font-family: $font-family--body;
    text-align: center;
  }

  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: center;
    align-items:     stretch;

    position: relative;
    z-index: 10;
  }

}