//
// Project
// --------------------------------------------------

.single-project {
  .content-block {

    @include from(ld) {
      padding-top: rem-calc(104);
      padding-left: rem-calc(252);
      padding-right: rem-calc(252);
    }
  }
}

.project__address {
  margin-bottom: $spacer-mid;

  * {
    font-style: normal;
    text-transform: uppercase;
  }

  .project__address--city {
    font-weight: $font-weight--black;
  }
}

.project__description {
  font-weight: $font-weight--light;
}

.project__hero {
  display: flex;
  flex-direction: column;

  &--left {}
  &--middle {}
  &--right {
    padding-top: $spacer-mid;
  }

  @include from(d) {
    flex-direction: row;

    &--left {
      flex-basis: 10%;
    }
    &--middle {
      flex-basis: 50%;
      padding-top: $spacer-large;
      text-align: left;
    }
    &--right {
      flex-basis: 40%;
      justify-content: flex-end;
      padding-top: rem-calc(88);
    }
  }
}

.project__logo {

  img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 40%;
  }

  @include from(d) {
    text-align: left;

    img {
      height: auto;
      margin: 0;
      max-width: 70%;
      min-width: rem-calc(408);
      width: auto;
    }
  }
}

.project__name {
  font-size: $font-size--h6;
  font-weight: bold;
  line-height: 2;
  max-width: rem-calc(150);
  text-align: right;

  @include from(pt) {
    max-width: 7.5rem;
  }

  @include from(pt) {
    font-size: $font-size--h4;
    max-width: 12rem;
  }  

  @include from(lt) {
    max-width: 11rem;
  }

  @include from(d) {
    max-width: 8rem;
  }
}

.project__type {
  border-bottom: rem-calc(3) solid $_color-base-brand;
  display: inline-block;
  font-size: $font-size--small;
  font-weight: $font-weight--medium;
  line-height: 3;
  margin-left: -2rem;
  margin-bottom: 0;
  padding-left: 5rem;
  text-align: left;
  text-transform: uppercase;

  @include from(pt) {
    font-size: rem-calc(18);
    margin-left: -3rem;
    padding-left: 10rem;
  }

  @include from(lt) {
    font-size: rem-calc(18);
    margin-left: -4rem;
  }

  @include from(d) {
    margin-left: rem-calc(-252);
    padding-left: 14rem;
  }
}

.project__square_footage,
.project__units {
  border-bottom: 1px solid $color--border;
  display: flex;

  &--label,
  &--value {
    line-height: 3;
  }

  &--label {
    flex-basis: 20%;
    text-transform: uppercase;
  }
  &--value {
    flex-basis: 80%;
    font-weight: $font-weight--black;
    padding-right: 1rem;
    text-align: right;
  }
}

.project__square_footage {}

.project__units {
  border-top: 1px solid $color--border;
  margin-bottom: 0;
}

.project__website {
  display: block!important;
  margin: 0 auto;
  max-width: rem-calc(273);
  text-transform: uppercase;

  @include button($_color-base-brand, $_color-base-brand);

  &:hover {
    background-color: $_color-base-brand;
    color: palette(white);
  }

}