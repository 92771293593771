//
// Animation
// --------------------------------------------------

@keyframes page-nav-slide-in {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes page-nav-bounce-in {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes wobbleHorz {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(25%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(0%);
  }
}

// Animate in from the right
@keyframes inFromRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Animate out to the right
@keyframes outToRight {
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

// Animate in from the left
@keyframes inFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Animate out to the left
@keyframes outToLeft {
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

// Nav slide in down
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}