//
// Typography
// --------------------------------------------------

html {
  color: $color--text;
  line-height: $line-height--base;
  font-family: $font-family--body;
  font-size: 16px;
  font-weight: $font-weight--body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  @include from(sd){
    font-size: 16px;
  }

  @include from(d) {
    font-size: 18px;
  }

  @include from(ld) {
    font-size: 20px;
  }

  @include from(xld) {
    font-size: 24px;
  }

  &.lt-ie9 {
    font-size: 18px;
  }
}



// Links
a {
  // @extend %dft;
  // font-weight: $font-weight--bold;
  cursor: pointer;
  text-decoration: none;
  @include link-color($color--link);
}



// Headings
h1, h2, h3, h4, h5, h6, p, ul, ol, span {
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga', 'clig';
}

p, ul, ol {
  margin-top: 0;
  margin-bottom: rem-calc(30);

  &:last-child {
    margin-bottom: 0;
  }
}


h1, .alpha,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
  margin-top: 0;
  margin-bottom: $spacer-small;

  font-family: $font-family--heading;
  font-weight: 400;
  line-height: 1.2;
  text-transform: lowercase;

  &.heading {
    color: $color--text;
    line-height: 0.75em;

  }
}



h1, .alpha {
  line-height: 1;
  font-size: rem-calc($font-size--h1 * $header-reduction--mobile);

  @include from(lp) {
    font-size: rem-calc($font-size--h1);
  }

  &.hero__heading {
    font-size: rem-calc(96);
    text-align: center;
    opacity: 0.85;

    @include from(lt) {
      font-size: rem-calc(123);
    }

    @include to(pp) {
      font-size: 48px;
      line-height: 0.7em;
      .home &{
        font-size: 50px;
      }
    }

    .fancy-font {
      font-size: rem-calc(165);
      opacity: 0.95;
      text-transform: none;
      margin-top: $spacer-smallest;

      .home & {
        margin-top: rem-calc($spacer-unit *2);
        @include from(lp) {
          margin-top: 0;
        }
      }

      @include from(lt) {
        font-size: rem-calc(230);
      }

      @include to(pp) {
        font-size: 72px;

        .home & {
          font-size: 96px;
        }
      }

      @include breakpoint($tiny-phone) {
        &,
        .home & {
          font-size: 80px;
        }
      }
    }
  }
}

h2, .beta {
  margin-top: -0.25em;
  line-height: 0.7em;
  font-size: rem-calc($font-size--h2 * $header-reduction--mobile);

  @include from(pt) {
    font-size: rem-calc($font-size--h2);
  }

  @include to(pp) {
    font-size: 40px;
  }

  &.heading {
    margin-top: -0.25em;
    line-height: 0.7em;

    .fancy-font {
      font-size: 64px;
      opacity: 0.9;

      @include from(pt) {
        font-size: rem-calc(120);
      }


    }
  }
}

h3, .gamma {
  margin-top: -0.25em;
  line-height: 0.7em;

  font-weight: $font-weight--regular;
  font-size: rem-calc($font-size--h3 * $header-reduction--mobile);

  @include from(pt) {
    font-size: rem-calc($font-size--h3);
  }

  &.heading {
    margin-top: -0.25em;

  }

  &.sub-heading{
    margin-bottom: $spacer-smallest/2;

    color: palette(brand);
    line-height: 0.7em;
  }
}

h4, .delta {
  font-size: rem-calc($font-size--h4);
  font-weight: $font-weight--regular;
  line-height: 0.7em;
  margin-top: -0.2em;
  margin-bottom: $spacer-smallest;
  text-transform: lowercase;

  &.sub-heading {
    @extend .epsilon;
  }
}

h5,
.epsilon,
h6,
.zeta,
.tag-line{
  display: block;

  font-family: $font-family--body;
  font-size: rem-calc(18);
  font-weight: 700;
  line-height: 1em;
  letter-spacing: $letter-spacing--small;
  text-transform: uppercase;
}

h5,
.epsilon {
  margin-bottom: $spacer-smallest;
}

h6,
.zeta{
  color: palette(brand);
  margin-bottom: $spacer-smallest;
}

.fancy-font{
  display: block;
  font-family: $font-family--special;
  transform: rotate(-5deg);
  line-height: 0.7em;
}

hr {
  border: 0;
  height: 1px;
  margin: rem-calc(25 0);
}



// Paragraphs
p,
%p {
  word-break: normal;
  word-wrap: normal;
}

div,
span,
li,
p{
  font-size: 18px;

  @include from(pt) {
    font-size: rem-calc(24);
  }

  @include from(sd) {
    font-size: rem-calc(26);
  }
}

strong {
  font-weight: 700;
}

pre {
  font-size: rem-calc(11);
}

q {
  font-style: italic;
}



// Marks
mark,
.marker {
  background: palette(brand);
  color: #fff;
  border-radius: rem-calc(10);
  padding: .1em .5em;
  font-size: rem-calc(12);
  text-transform: capitalize;
}
